.home-blog {
  width: 100%;
  margin-top: 27px;
  margin-bottom: 79px;
}
.home-blog-wrap {
  max-width: 1280px;
  margin: auto;
  padding-left: 50px;
  padding-right: 50px;
}
.h-blog-div {
  display: flex;
  flex-direction: row;
}
.h-blog-content {
  width: 40%;
  padding-top: 40px;
}
.h-blog-slider-div {
  width: 60%;
}
.h-blog-sec1 {
  width: 79%;
}
.h-blog-sec1 .title {
  color: #02245b;
  font-family: var(--font-rubik);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px; /* 150% */
}
.h-blog-sec1 .des {
  color: rgba(0, 0, 0, 0.65);
  font-family: var(--font-base);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px; /* 181.25% */
  margin-top: 25px;
  text-align: justify;
}
.h-blog-sec1 .title2 {
  color: #000;
  font-family: var(--font-base);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 75.5px; /* 471.875% */
}
.input-btn-div {
  display: flex;
}
.input-btn-div button {
  border-radius: 0px 10px 10px 0px;
  background: #02245b;
  color: #fff;
  font-family: var(--font-base);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: 57px;
  border: none;
  width: 40%;
}
.input-btn-div button:hover {
  box-shadow: 0 0 2px 3px #02245b7c;
}
.input-btn-div input {
  border-radius: 10px 0 0 10px;
  background: #fff;
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.09);
  color: rgba(0, 0, 0, 0.65);
  font-family: var(--font-base);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 75.5px; /* 539.286% */
  height: 57px;
  border: none;
  width: 60%;
  padding-left: 20px;
}
.recentBlogCard_main_wrapp1 {
  /* min-width: 250px; */

  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.06);
  /* height: 425px; */
  /* margin: 10px; */
  margin-left: 0px;
  border: none;
  transition: all ease 0.5s;
  width: calc(96% - 4px) !important;
  height: 445px;
  margin-top: 20px;
  margin-bottom: 20px;
  /* max-width: 350px; */
  cursor: pointer;
}
.recentBlogCard_shap1 {
  position: absolute;
  bottom: -1px;
  right: -1px;
  /* height: 100px; */
  width: 110px;
}
.recentBlogCard_text_wrapp1 {
  width: 100%;
  height: 38%;
  padding: 1rem;
}
.recentBlogCard_img1 {
  width: 99.9%;
  height: 99.9%;
  aspect-ratio: 1 / 1;
  border-radius: 22px;
  border-bottom: 1px solid white;
  object-fit: cover;
}
.recentBlogCard_arrow1 {
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 60px;
  width: 60px;
  transition: all ease 0.5s;
  cursor: pointer;

}
.recentBlogCard_img_wrapp1 {
  position: relative;
  width: 100%;
  height: 60%;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}
.recentBlogCard_main_wrapp1:hover {
  transform: translateY(10px);
}
.recentBlogCard_main_wrapp1:hover .recentBlogCard_arrow1 {
  transform: rotate(45deg);
  transition: all ease 0.3s;
}
.blog-slider-button-div {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
  /* margin-top: 22px; */
  width: 100%;
  height: 40px;
  /* margin-left: auto;
    margin-right: auto; */
  /* padding-left: 6px; */
}
.blog-slider-button {
  width: 6%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blog-slider-button > img {
  /* width: 32px;
  height: 32px; */
}

.blog-card {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.09);
  width: calc(91% - 16px) !important;
  height: 510px;
  margin-left: 40px;
}
.h-blog-slider {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* margin: 10px 0px; */
}
.blog-arrow-div {
  display: flex;
  justify-content: center;
  /* margin-right: 50px; */
}
.blog-car-img {
}
.blog-car-img img {
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  height: 232px;
}
.blog-card-title {
  color: #000;
  font-family: var(--font-base);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  margin-bottom: 10px;
}
.blog-card-del {
  color: #919191;
  font-family: var(--font-base);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.5px;
  text-align: justify;
}
.blog-card-des {
  padding: 18px;
}
.h-blog-slider .slick-dots li.slick-active button:before {
  opacity: .75;
  color: #FF5E14;
  font-size: 15px;
}
.h-blog-slider .slick-dots {
bottom: -31px;
width: 50%;
right: 0;
left: 0;
margin: auto;
}

.val_1_join {
  /* width: 50%; */
  /* margin-top: -16px; */
  font-size: 13px;
  color: 	#ff3333;
  font-family: var(--font-base);
  /* position: fixed; */
  /* margin-left: 6px; */
  margin-top: 10px;
  font-weight: 600;
  padding-left: 3px;
}
.join-form{
  width: 100%;
}
.h-blog-slider .slick-list {
  /* height: 547px!important; */
  height: 519px!important;
}
.h-blog-slider .slick-slide {
  margin-right: 4px!important;
} 

@media screen and (max-width: 769px) {
  .home-blog-wrap {
    padding-left: 0;
    padding-right: 0;
    width: 90%;
    margin: auto;
  }
  .h-blog-div {
    flex-direction: column;
  }
  .h-blog-content {
    width: 100%;
    padding-top: 17px;
  }
  .h-blog-slider-div {
    width: 100%;
    margin-top: 45px;
  }
  .blog-card {
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 0px) !important;
    height: 450px;
  }
  .h-blog-sec1 {
    width: 100%;
    margin: auto;
  }
  .blog-card-title {
    /* height: 81px; */
  }
  .h-blog-sec1 .title {
    font-size: 25px;
    line-height: 30px;
  }
  .home-blog {
    padding-bottom: 0px;
  }
  .h-blog-slider-div .slick-dots {
    left: 0px !important;
    text-align: center !important;
    padding-right: 0;
    bottom: -76px !important;
  }
  .blog-arrow-div {
    /* justify-content: center;
    margin-right: 0; */
    justify-content: center;
    margin-right: 0;
    margin: auto;
    margin-top: 22px;
  }
  .recentBlogCard_img_wrapp1 {
    height: 55%;
  }
  .recentBlogCard_main_wrapp1 {
    margin-left: 11px;
    margin-right: auto;
    width: calc(100% - 20px) !important;
    height: 500px;
  }
  .blog-slider-button {
    width: 19%;
  }
  .blog-slider-button-div {
    /* width: 88%; */
    padding-left: 0;
  }
  .h-blog-slider .slick-list {
    height: auto!important;
  }
  .h-blog-slider .slick-slide {
    margin-right: 0!important;
  } 
  .blog-card-del {
    text-align: justify;
  }
  .h-blog-slider-div .slick-dots li {
    padding: 21px 12px !important;
}

}

@media (min-width: 769px) and (max-width: 1024px) {
  .h-blog-slider-div .slick-dots {
    left: 0px !important;
    text-align: center !important;
    padding-right: 0;
    bottom: -55px;
  }
  .blog-arrow-div {
    justify-content: center;
    margin-right: 0;
  }
  .recentBlogCard_main_wrapp1 {
    margin-right: auto;
    width: calc(90% - 1px) !important;
    height: 425px;
  }

  .recentBlogCard_img_wrapp1 {
    height: 54%;
  }

  .blog-slider-button > img {
   
  }
  .blog-card-title {
    font-size: 17px;
    line-height: 21px;
    /* height: 75px; */
  }
  .blog-card-del {

    font-size: 13px;
  }
  .h-blog-slider-div .slick-dots li {
    padding: 21px 12px !important;
}
.h-blog-slider-div .slick-dots li button:before {
  width: 28px !important;
}
.h-blog-content {
  width: 50%;
  padding-top: 40px;
}
.h-blog-slider-div {
  width: 50%;
}
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .blog-card-title {
    font-size: 15px;
    line-height: 21px;
    /* height: 75px; */
  }
  .blog-card-del {
    font-size: 12px;
  }
  .blog-card {
    height: 468px;
  }


  .blog-slider-button {
    width: 10%;
}
}
@media (min-width: 1200px) and (max-width: 1275px) {
  .recentBlogCard_img_wrapp1 {
    height: 56%;
  }
}
