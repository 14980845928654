.programdes-main{
    max-width: 1170px;
    margin: auto;
    margin-top: 15px;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: 0px 0px 14px -6px rgba(0, 0, 0, 0.5);

}
.programdes_wrap{
    width: 100%;
    padding-top: 37px;
}
.pro_des_title{
    color: #002147;
    font-family: var(--font-rubik);
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 62.01px; /* 140% */
}
.pro_webinar_title{
    color: #002147;
    font-family: var(--font-rubik);
    font-size: 29px;
    font-style: normal;
    font-weight: 700;
    line-height: 62.01px;
}
.pro_des_content >p{
    color: #444444;
    font-family: var(--font-base);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    text-align: justify;
}
.head-bottom{
    height: 4.13px;
    background: #FF8000;
    border: none;
    width: 55.12px;
    border-radius: 8.27px;
    margin-bottom: 13px;
}
.eligibility-list>ul>li{
    font-size: 14px;
    color: #444444;
    font-family: var(--font-base);
    font-weight: 600;
    line-height: 24px;
    padding-bottom: 20px;
    display: flex;
    align-items: start;
    gap: 16px;
    text-align: justify;
}
/* .eligibility-list>ul>li>img{
    width: 21px;
    height: 21px;
} */
.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    grid-auto-rows: minmax(30px, auto); /* Minimum row height of 100px */
    gap: 20px; /* Gap between grid items */
  }
  
  .grid-item {
    display: flex;
    align-items: flex-start;
    /* justify-content: center; */
    font-size: 14px;
    line-height: 18px;
    font-family: var(--font-base);
    font-weight: 600;
    color: #444444;
    gap: 25px;
  }
  .fee-list{
    margin-left: 15px;
  }
  .app-link{
    text-decoration: underline;
  }
.fee-list>ul>li{
    list-style: initial;
    font-size: 14px;
    line-height: 28px;
    font-family: var(--font-base);
    font-weight: 600;
    color: #444444;
    text-align: justify;
    padding-bottom: 7px;
}
.apply-btn-div{
    margin:50px 0px;
    padding-bottom: 55px;
    /* padding-bottom: 10px; */
}
.apply-btn-div>button{
    width: auto;
    height: 57px;
    background-color:#02245B;
    color: white;
    font-size: 18px;
    font-family: var(--font-base);
    font-weight: 600;
    line-height: 24.51px;
    padding: 1rem 2rem;
    border-radius: 5px;
}
.underlinepro{
    /* text-decoration: underline; */
    font-weight: 700;
}
.application-list>ul>li{
    font-size: 14px;
    color: #444444;
    font-family: var(--font-base);
    font-weight: 600;
    line-height: 24px;
    padding-bottom: 20px;
    display: flex;
    align-items: start;
    gap: 16px;
}
.application-list>ul>li>p{
    font-size: 14px;
    color: #444444;
    font-family: var(--font-base);
    font-weight: 600;
    line-height: 24px;
    /* display: flex; */
    align-items: start;
    gap: 16px;
    text-align: justify;
}
.pro-des-sub-div>ul>li{
    font-size: 14px;
    color: #444444;
    font-family: var(--font-base);
    font-weight: 600;
    line-height: 24px;
    display: flex;
    align-items: start;
    gap: 8px;
    flex-direction: column;
    margin-top: 5px;
}
.pro-des-sub-title{
    font-size: 15px;
    color: #444444;
    font-family: var(--font-base);
    font-weight: 600;
    line-height: 24px;
}
.pro-des-sub-del{
    margin-left: 32px;
}
.pro_des_content_duty>p{
    display: flex;
    gap: 10px;
    /* justify-content: center; */
    align-items: start;
    margin-bottom: 10px;
    font-size: 14px;
    color: #444444;
    font-family: var(--font-base);
    font-weight: 600;
    line-height: 24px;
}
.pro_des_content_p{
    font-size: 14px;
    color: #444444;
    font-family: var(--font-base);
    font-weight: 600;
    line-height: 24px;
}
.pro-des-sub-div-au{
    margin-top: 15px;
}
.pro-des-sub-div-au>ul>li{
    font-size: 14px;
    color: #444444;
    font-family: var(--font-base);
    font-weight: 700;
    line-height: 24px;
    display: flex;
    align-items: start;
    gap: 8px;
    flex-direction: column;
    margin-top: 15px;
}
.pro-des-sub-del{
    font-weight: 600;
    font-family: var(--font-base);
    font-size: 14px;
}

.au-list{
    list-style: initial;
    margin-bottom: 7px;
}
.apply-btn-new{
    width: 238px;
    height: 57px;
    background-color: #02245B;
    color: white;
    font-size: 18px;
    font-family: var(--font-base);
    font-weight: 600;
    line-height: 24.51px;
    padding: 10px 33px 10px 33px;
    border-radius: 5px;
}
.eligibility-list-list1{
    font-size: 14px;
    color: #444444;
    font-family: var(--font-base);
    font-weight: 600;
    line-height: 24px;
    padding-bottom: 20px;
    display: flex;
    align-items: start;
    gap: 16px;
    text-align: justify;
}
.checkout_program{
    padding-bottom: 55px;
    position: relative;
    z-index: 0;
    margin-bottom: 5rem;
}
.checkout_program > h3{
    color: #002147;
    font-family: var(--font-rubik);
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
}
.home-section2_new {
    /* background: rgba(19, 44, 69, 0.92); */
    /* height: 90vh; */
position: relative;
z-index: -1;
pointer-events: auto;
    /* margin-top: 58px; */
    /* margin-top: -5px; */
    width: 100%;
}
.h-checkout-text{
    color: var(--color-blue) !important;;
    font-family: var(--font-rubik);
    font-size: 36.001px;
    font-style: normal;
    font-weight: 700;
    line-height: 43.202px;
    display: flex;
    /* text-align: center; */
    justify-content: center;
    align-items: center;
}
.sec2-card-new {
    width: calc(23% - 3px);
    /* margin: 16px; */
    /* width: 282px; */
    height: 367px;
    flex-shrink: 0;
    position: relative;
    border-radius: 35px;
    transition: 0.5s;
    cursor: pointer;
    overflow: hidden;
}
.sec2-card-new img {
    width: 100%;
    height: 100%;
    border-radius: 35px;
    position: relative;
    object-fit: cover;
    
}
.sec2-card-new::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      #3588d7 0%,
      #2487e4 7.16%,
      rgba(11, 134, 248, 0.6) 17.43%,
      rgba(14, 72, 130, 0) 38.46%
    );
    z-index: 2;
    border-radius: 35px;
    /* opacity: 0.69; */
    /* transition:0.5s; */
    transition: all 0.5s cubic-bezier(0.48, -0.28, 0.41, 1.4);
  }

  /* when hovering the card */
  .sec2-card-new:hover .sec2-card-del {
    height: auto; /* Show full content on hover */
    z-index: 10;
    bottom: 72%;
    left: 0;
    text-align: center;
    transform: translateY(0px);
    /* padding: 37px; */
    /* transition: height 0.5s ease; */
  }
  .sec2-card-new:hover .sec2-card-del1 {
    height: auto; /* Show full content on hover */
    z-index: 10;
    bottom: 72%;
    left: 0;
    text-align: center;
    transform: translateY(0px);
    /* padding: 37px; */
    /* transition: height 0.5s ease; */
  }
  
  .sec2-card-new:hover .full-details {
    display: block; /* Show full content on hover */
    padding: 0 26px 0 26px;
  }
  .sec2-card-new:hover .p-des {
    display: none; /* Ensure it's above other content */
  }
  .sec2-card-new:hover .p-title {
    padding-left: 1px;
    padding-right: 1px;
    padding-bottom: 13px;
  }
  .sec2-card-new:hover::before {
    background: linear-gradient(
      0deg,
      #3588d7 0%,
      #2487e4 7.16%,
      rgba(11, 134, 248, 0.6) 101.43%,
      rgba(14, 72, 130, 0) 154.46%
    );
    z-index: 2;
    border-radius: 35px;
    opacity: 18.69;
    transition: 0.5s;
  }
  
  .sec2-card-new:hover .sec2-card-del::before {
    content: " ";
    display: block;
    width: 19px;
    height: 3px;
    background: #fff;
    position: absolute;
    left: 0;
    top: 5%;
  }
  .sec2-card-new:hover .sec2-card-del::after {
    content: " ";
    display: block;
    width: 19px;
    height: 3px;
    background: #fff;
    position: absolute;
    right: 0;
    top: 5%;
  }
  .sec2-card-new:hover .sec2-card-del1::before {
    content: " ";
    display: block;
    width: 19px;
    height: 3px;
    background: #fff;
    position: absolute;
    left: 0;
    top: 7%;
  }
  .sec2-card-new:hover .sec2-card-del1::after {
    content: " ";
    display: block;
    width: 19px;
    height: 3px;
    background: #fff;
    position: absolute;
    right: 0;
    top: 7%;
  }
  .sec2-card-new:hover .p-title span {
    padding-left: 0px;
  }

  .panno-pro-list{
    padding-left: 1rem;

  }
  .panno-pro-list >ul{
    list-style-type: decimal;
  }
  .panno-pro-list>ul>li{
    font-size: 14px;
    line-height: 28px;
    font-family: var(--font-base);
    font-weight: 600;
    color: #444444;
    text-align: justify;
    padding-bottom: 7px;
}
  
.program-webinar-btn>button{
  width: 159px;
    height: 44px;
    background-color: #1ec199;
    color: white;
    font-size: 18px;
    font-family: var(--font-base);
    font-weight: 600;
    line-height: 24.51px;
    /* padding: 10px 33px 10px 33px; */
    border-radius: 5px;
    margin-top: 2rem;
}
  
@media screen and (max-width: 768px) {
  .program-webinar-btn>button{
    font-size: 16px;
  }
    .pro_des_title {
      font-size: 20px;
      line-height: 28.805px;
        /* text-align: center; */
    }
    .pro_des_content {
        color: #000;
        font-family: var(--font-urbanist);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-align: justify;
    }
    .programdes_wrap {
        width: 90%;
        margin: auto;
    }
    
    .programdes-main {
        width: 90%;
        padding-left: 0px;
        padding-right: 0px;
    } 
    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* 3 columns */
      } 
    .apply-btn-div{
        width: 85%;
        margin: 50px auto;
    }  
    .sec2-card-new {
        /* width: 90%; */
        /* width: auto; */
       width: 230px;
       height: 319px;
      }
      .apply-btn-div>button{
        height: auto;
        font-weight: 600;
        line-height: 24.51px;
        padding: 10px 2rem;
        width: 216px;
        font-size: 16px;

      }
      .apply-btn-new {
        font-size: 16px;
      }
      .pro_webinar_title {
        font-size: 22px;
      }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .sec2-card-new {
      /* width: calc(47% - 20px);  */
      width: calc(47% - 20px);
      /* width: auto; */
    }
}
@media (min-width: 1025px) and (max-width: 1211px) {
    .sec2-card-new {
        width: calc(29% - 20px);
        /* width: 282px; */
        height: 336px;
      }
}
@media (min-width: 1211px) and (max-width: 1270px) {
    .sec2-card {
        width: calc(17% - 3px);
    }
}
@media only screen and (max-width: 480px) {
    .grid-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr); /* 3 columns */
      }   
}
@media screen and (max-width: 1170px) {
    .programdes-main {
      max-width: 90%;
    }
  }