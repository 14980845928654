.testimonial__main_wrapp {
  height: 523px;
  width: 100vw;
  overflow: hidden;
  position: relative;
  background-image: url("../../assets/images/testimonial/testimonial-banner2.webp"); /* Replace 'your-image.jpg' with your image URL */
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 45px;
}

.testimonial__wave_img1 {
  max-width: 100vw;
  width: 100vw;
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 640px;
}

.testimonial__text_section_wrapp {
  position: absolute;
  width: 100vw;
  left: 0px;
  top: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial__text_section_inner_wrapp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3px;
}
@media (max-width: 481px) {
  .testimonial__main_wrapp {
    height: 50vh;
    background-size: cover; /* Adjust background size for smaller screens */
    background-position: start;
    background-image: url("../../assets/images/testimonial/testBanner_mob.webp");
  }

  .testimonial__wave_img1 {
    display: none;
  }

  .testimonial__wave_img {
    max-width: 100vw;
    width: 100vw;
    position: absolute;
    bottom: 0px;
    left: 0px;

    height: 30vh;
    background-image: url("../../assets/images/herowave_2.png"); /* Replace 'your-image.jpg' with your image URL */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .testimonial__text_section_inner_wrapp {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    /* width: 60%; */
  }

  .des_shadow_text,
  .large_shadow_text {
    text-align: center;
    color: #fff;
  }

  .des_shadow_text {
    font-size: 38px;
  }

  .des_shadow_text {
    font-size: 16px;
  }

  .testimonial__text_section_wrapp {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 77%;
    left: 50%;
    transform: translate(-50%, -6%);
    z-index: 1;
  }
  .large_shadow_text_white {
    font-size: 28px;
}
}
@media (max-width: 768px) {
  .testimonial__main_wrapp {
    height: 50vh;
    background-size: cover; /* Adjust background size for smaller screens */

  }

  .testimonial__wave_img1 {
    display: none;
  }

  .testimonial__wave_img {
    max-width: 100vw;
    width: 100vw;
    position: absolute;
    bottom: 0px;
    left: 0px;

    height: 30vh;
    background-image: url("../../assets/images/herowave_2.png"); /* Replace 'your-image.jpg' with your image URL */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .testimonial__text_section_inner_wrapp {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    /* width: 60%; */
  }

  .des_shadow_text,
  .large_shadow_text {
    text-align: center;
    color: #fff;
  }

  .des_shadow_text {
    font-size: 38px;
  }

  .des_shadow_text {
    font-size: 16px;
  }

  .testimonial__text_section_wrapp {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 77%;
    left: 50%;
    transform: translate(-50%, -6%);
    z-index: 1;
  }
  .large_shadow_text_white {
    font-size: 28px;
}
}

@media (min-width: 769px) and (max-width:1024px){
  .testimonial__main_wrapp {
    height: 70vh;
    background-image: url("../../assets/images/testimonial/testBanner_mob.webp");
    background-size: cover; 

  }
}