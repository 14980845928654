.aboutwhychoose_inner_wrapp {
  display: flex;
  width: 100%;
  height: 96vh;
  align-items: center;
  justify-content: space-between;
  gap: 2%;
}

.aboutwhychoose_img_wrapp {
  width: 48%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.about_whychoose_des_text {
  font-family: var(--font-base);
  font-size: 14px;
  font-weight: 600;
  line-height: 29px;
  margin-bottom: 20px;
  letter-spacing: 0em;
  text-align: justify;
  color: var(--color-light-gray);
}

.about_whychoose_boxes_wrapp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  margin: 10px auto;
  width: 100%;
}

.about_whychoose_boxes_wrapp > div {
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px dashed #ff5e14;
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.about_whychoose_boxes_wrapp > div > p {
  font-family: var(--font-base);
  font-size: 16px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--color-black);
}

.aboutwhychoos_textwrapp {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  width: 47%;
  height: 100%;
  padding: 1vh 10px;
}

@media (max-width: 768px) {
  .about_whychoose_des_text {
    text-align: justify;
    margin-bottom: 1rem;
  }

  .aboutwhychoose_inner_wrapp {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin: 1rem auto; */
    height: 100%;
    gap: 0rem;
    /* padding-right: 13px; */
  }

  .aboutwhychoose_img_wrapp {
    width: 100%;
    height: 311px;
    padding-right: 13px;
  }

  .aboutwhychoos_textwrapp {
    width: 100%;
    align-items: center;
    margin: 1rem auto;

  }
}
