.join-students{
    width: 100%;
    margin-top: 7%;
    margin: auto;
    position: relative;
    margin-bottom: 0px;
}
.join-students-wrap{
    max-width: 1280px;
    margin: auto;
    margin-top: 18px;
}
.bg-img {
    position: relative;
    /* top: 0; */
    right: 0;
    left: 0;
    bottom: 95px;
    pointer-events: none;
}
.bg-img img{
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
.student-content{
    /* position: relative;
    bottom: 264px; */
    position: absolute;
    bottom: 100px;
    /* display: flex; */
    left: 0;
    right: 0;
    max-width: 1280px;
    margin: auto;
}
.student-content .title{
    color: #000;
    text-align: center;
    font-family: var(--font-rubik);
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 17.609px; /* 48.914% */
    padding-bottom: 13px;
}
.student-content .des{
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    font-family: var(--font-base);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px; /* 181.25% */
    width: 526px;
    margin: auto;
}
.student-count{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 65%;
    margin: auto;
    margin-top: 60px;
}


.count-sec{
    display: flex;
    flex-direction: column;
    width: 33.33%;
    align-items: center;
    justify-content: center;
}
.pink-text{
    color: #FB5A8A;
    text-align: center;
    font-family: var(--font-base);
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 47.565px; /* 159.07% */
}
.blue-text{
    color: #2FC9F7;
text-align: center;
font-family: var(--font-base);
font-size: 38px;
font-style: normal;
font-weight: 700;
line-height: 47.565px; /* 125.17% */
}
.green-text{
    color: #54CF80;
    text-align: center;
    font-family: var(--font-base);
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 47.565px; /* 125.17% */
}
.count-des{
    color: rgba(0, 0, 0, 0.79);
    text-align: center;
    font-family: var(--font-base);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 47.565px; /* 237.823% */
}

@media screen and (max-width: 768px){
    .student-content {
        bottom: 40px;
        width: 90%;
        margin: auto;
        position: relative;
    }
    .student-content .title {
        font-size: 28px;
        line-height: 36px;
        margin-top: -80px;
    }
    .student-content .des {
        font-size: 13px;
        line-height: 24px;
        width: 100%;
        text-align: justify;
    }
    .student-count{
        width: 85%;
        align-items: center;
        flex-direction: column;
        margin-top: 27px;
    }
    .count-des {
        font-size: 18px;
        line-height: 23.565px;
    }
    .count-sec{
        width: 100%;
        padding-bottom: 20px;
        flex-direction: column;
    }
    .pink-text , .blue-text,.green-text{
        font-size: 30px;
    }
    .bg-img {
        top: -27px;
    }
}

@media (min-width: 769px) and (max-width:1024px){
    .student-content{
        bottom: 150px;
        position: unset;
    }
    .pink-text , .blue-text,.green-text{
        font-size: 30px;
    }
    .count-des{
        font-size: 14px;
    }
    .student-count {
        width: 80%;
    }
}
@media (min-width: 1025px) and (max-width:1211px){
    .student-content{
        bottom: 11px;
    }
    .pink-text , .blue-text,.green-text{
        font-size: 30px;
    }
    .count-des{
        font-size: 14px;
    }
}