.contact-section3 {
  width: 100%;
  margin-top: 107px;
  background-color: #02245b;
  height: auto;
}

.contact-sec3-wrap {
  max-width: 1280px;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: row;
}

.contact-sec3-content-div {
  width: 49%;
  padding-left: 38px;
  /* padding-top: 10%; */
  margin: auto;
    /* margin-left: 45%; */
    margin-top: 10%;
}

.con-sec3-title {
  color: #ebebeb;
  font-family: var(--font-rubik);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 47px;
  /* 130.556% */
  text-transform: capitalize;
}

.con-sec3-des {
  color: rgba(255, 255, 255, 0.65);
  font-family: var(--font-base);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 27.5px;
  /* 171.875% */
  letter-spacing: 0.6px;
  text-align: justify;
}

.contact-sec3-form-div {
  /* position: absolute; */
  left: 0;
  top: -129px;
  width: 41%;
  /* padding-right: 50px; */
  padding-left: 50px;
  transform: translateY(-66px);
}

.contact-form {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 15px;
  border: 2.04px dashed #1ec199;
  background: #fff;
  box-shadow: 0px 0px 10px 5px rgba(173, 173, 173, 0.1);
  /* height: 585px; */
}

.contact-form h2 {
  color: #040430;
  text-align: center;
  font-family: var(--font-rubik);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38.967px;
  /* 121.771% */
  margin-bottom: 25px;
  margin-top: 14px;
}

.form-group {
  margin-bottom: 15px;
}

.contact-form label {
  color: #1ec199;
  font-family: var(--font-base);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.619px;
  /* 122.619% */
}

.form-name {
  border-radius: 10.218px;
  background: #e5d8fd;
  width: 100%;
  height: 47px;
  border: none;
  /* margin-top: 11px; */
  padding-left: 14px;
}

.form-textarea {
  border-radius: 10.218px;
  background: #e5d8fd;
  width: 100%;
  border: none;
  height: 130px;
  /* margin-top: 11px; */
  padding: 10px;
}
.form-select {
  border-radius: 10.218px;
  background: #e5d8fd;
  width: 100%;
  border: none;
  height: 47px;
  /* margin-top: 11px; */
  padding: 10px;
  appearance:none;
  cursor: pointer;
  
  /* z-index: 3; */
  /* -webkit-appearance: listbox !important; */

}
.select_arrow {
  position: absolute;
  right: 20px;
  bottom: 12px;
  color: #838080;
  stroke-width: 2;
  /* z-index: 0; */
  pointer-events: none;
}

.contact-form button {
  border-radius: 35.764px;
  border: 1.022px solid #1ec199;
  background: #1ec199;
  color: #fff;
  text-align: center;
  font-family: var(--font-base);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.619px;
  /* 122.619% */
  letter-spacing: 1.533px;
  width: 77%;
  height: 45px;
  transition: box-shadow 0.3s ease-in-out;
  margin-bottom: 15px;
}

.contact-form button:hover {
  /* border: 3px solid var(--color-sky-blue); */
  box-shadow: 0 0 2px 3px #1ec198a4;
}

.contact-sec3-content-div button {
  border: none;
  background: transparent;
  width: 12%;
  display: flex;
  justify-content: flex-start;
}

.contact-sec3-content-div button img {
  width: 50px;
}

.con-social-btn-div {
  display: flex;
  flex-direction: row;
  margin-top: 31px;
}

.val_1 {
  /* width: 50%; */
  /* margin-top: -16px; */
  font-size: 13px;
  color: 	#ff3333;
  font-family: var(--font-base);
  /* position: fixed; */
  /* margin-left: 6px; */
  margin-top: 10px;
  width: 100%;
  /* font-weight: 600; */
}
.note-text {
  font-size: 13px;
  color:rgb(117 117 117);;
  font-family: var(--font-base);
  margin-top: 10px;
}
.validation {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.con-social-btn-div a {
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.con-social-btn-div .social-img-2 {
  display: none;
}

.con-social-btn-div a:hover .social-img-2 {
  display: block;
}

.con-social-btn-div a:hover .social-img-1 {
  display: none;
}

/* 13-04-2024 */
.contact_div {
  display: flex;
  gap: 10px;
}

.apply_btn_con {
  width: 50% !important;
  border: 2px solid 1ec199!important;
  background-color: transparent!important;
  color: #1ec199!important;
}

.clicked:hover {
  box-shadow: 0 0 2px 3px #1ec199 !important;
}

.clicked {
  background: #1ec199 !important;
  color: white!important;
  border: 1.022px solid transparent !important;
  border-radius: 35.764px;
  box-shadow: 0 0 2px 3px #1ec199 !important;
}
.validationr {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0px;
  flex-wrap: wrap;
}
.radio_btn{
  font-size: 16px;
    color: #574f4f;
    font-family: var(--font-base);
}
.radio_btn > input {
  margin-left: 16px;
}

/* modal */


.modal_screen {
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 20px;
  border-radius: 5px;
  transition: opacity 0.5s;
  border-radius: 10px;
  width: 23%;
  background-color: white;
  color: black;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 43%), 0 6px 20px 0 rgb(0 0 0 / 32%);
}
.modal_close {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.body_img {
  width: 91px;
}
.body_h1 {
  font-size: 32px;
  font-family: var(--font-600);
  margin-bottom: 20px;
}
.loading_screen {
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  /* transform: translate(-50%, -50%); */
  color: white;
  padding: 20px;
  border-radius: 5px;
  transition: opacity 0.5s;
  border-radius: 20px;
  width: 100%;
  height: 100vh;
  margin: auto;
  z-index: 99999;
  backdrop-filter: blur(35px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading_screen>img{
  width: 72px;
}
/* HTML: <div class="loader"></div> */
.loader_contact {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--color-blue);
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}

/* 13-04-2024 */

@media (max-width: 768px) {
  .contact_div {
    display: flex;
    gap: 10px;
    flex-direction: row;
  }

  .contact-section3 {
    width: 100%;
    margin-top: 54px;
    background-color: #02245b;
    height: auto;
  }

  .contact-sec3-content-div {
    width: 90%;
    padding-left: 0px;
    padding-top: 2%;
    margin: auto;
    padding-bottom: 10%;
  }
  

  .contact-sec3-wrap {
    flex-direction: column;
  }

  .contact-sec3-form-div {
    position: unset;

    width: 90%;
    margin: auto;
    padding-right: 0px;
    padding-left: 0;
    margin-top: 49px;
    padding-bottom: 45px;
    transform: translateY(0);

  }

  .con-sec3-title {
    text-align: center;
    font-size: 23px;
  }

  .con-sec3-des {
    text-align: justify;
  }

  .contact-sec3-content-div button {
    width: 15%;
  }

  .contact-form h2 {
    font-size: 22px;
  }

  .con-social-btn-div {
    justify-content: center;
    gap: 2rem
  }
  .contact-form button {
    font-size: 13px;
}
.modal_screen {
  width: 64%;
}
}

/* tablet view  */
@media (min-width: 768px) and (max-width: 1024px) {
  .contact_div {
    display: flex;
    gap: 10px;
    flex-direction: row !important;
  }

  .contact-section3 {
    width: 100%;
    margin-top: 10%;
    background-color: #02245b;
    height: auto;
  }

  .contact-sec3-content-div {
    width: 85%;
    padding-left: 0px;
    padding-top: 0;
    margin: auto;
    padding-bottom: 2%;
  }

  .contact-sec3-wrap {
    flex-direction: column;
  }

  .contact-sec3-form-div {
    position: unset;
    /* right: 0; */
    top: 0;
    width: 85%;
    margin: auto;
    padding-right: 0px;
    margin-top: 49px;
    padding-bottom: 45px;
    padding-left: 0;
    transform: translateY(0);
  }

  .con-sec3-title {
    text-align: center;
    font-size: 28px;
  }

  .con-sec3-des {
    text-align: justify;
  }

  .contact-sec3-content-div button {
    width: 8%;
  }

  .contact-form h2 {
    font-size: 25px;
  }
  .con-social-btn-div {
    margin-bottom: 31px;
    justify-content: center;
    gap: 1rem;
}
}

@media (max-width: 481px) {
  .radio_btn {
    padding-top: 12px;
    width: 100%;
  }
}