.contact__main_wrapp {
  height: 523px;
  width: 100vw;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.contact-banner-img{
  width: 100%;
  height: 824px;
  object-fit: cover;
  margin-top: -306px;
}
.contact__wave_img1 {
  max-width: 100vw;
  width: 100vw;
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 640px;
}

.contact__text_section_wrapp {
  position: absolute;
  width: 100vw;
  left: 0px;
  top: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact__text_section_inner_wrapp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3px;
}
@media (max-width: 481px) {
  .contact__main_wrapp {
    width: 100vw;
    overflow: hidden;
    position: relative;
    background-image: url("../../assets/images/contact/Contact\ us_mob.webp"); /* Replace 'your-image.jpg' with your image URL */
    background-size: cover;
    /* background-position: bottom; */
    height: 50vh;
    background-position: center -120px;

  }
  
    
  .contact__wave_img1 {
    display: none;
  }
  .des_shadow_text, .large_shadow_text {
    font-size: 28px;
}

  .contact__wave_img {
    max-width: 100vw;
    width: 100vw;
    position: absolute;
    bottom: 0px;
    left: 0px;

    height: 30vh;
    background-image: url("../../assets/images/herowave_2.png"); /* Replace 'your-image.jpg' with your image URL */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .contact__text_section_inner_wrapp {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    /* width: 60%; */
  }

  .des_shadow_text,
  .large_shadow_text {
    text-align: center;
    color: #fff;
  }

  .des_shadow_text {
    font-size: 38px;
  }

  .des_shadow_text {
    font-size: 16px;
  }

  .contact__text_section_wrapp {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 77%;
    left: 50%;
    transform: translate(-50%, -6%);
    z-index: 1;
  }
  .contact-banner-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: none;
  }
  
}

@media (min-width: 481px) and (max-width: 768px) {
  .contact__main_wrapp {
    height: 50vh;
    background-size: cover; /* Adjust background size for smaller screens */
    background-position: center -223px;
    background-image: url("../../assets/images/contact/Contact\ us_mob.webp");
  }
  .contact-banner-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: none;
  }

  .contact__wave_img1 {
    display: none;
  }
  .des_shadow_text, .large_shadow_text {
    font-size: 28px;
  }
  .des_shadow_text {
    font-size: 16px;
  }
  .contact-banner-img {
    transform: translateY(-27%);
}

  .contact__wave_img {
    max-width: 100vw;
    width: 100vw;
    position: absolute;
    bottom: 0px;
    left: 0px;

    height: 30vh;
    background-image: url("../../assets/images/herowave_2.png"); /* Replace 'your-image.jpg' with your image URL */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .contact__text_section_inner_wrapp {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
    /* width: 60%; */
  }

  .des_shadow_text,
  .large_shadow_text {
    text-align: center;
    color: #fff;
  }

  .des_shadow_text {
    font-size: 38px;
  }

  .des_shadow_text {
    font-size: 16px;
  }

  .contact__text_section_wrapp {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 76%;
    left: 50%;
    transform: translate(-50%, -6%);
    z-index: 1;
  }
}

@media (min-width: 769px) and (max-width:1024px){
  .contact__main_wrapp {
    height: 70vh;
    background-image: url("../../assets/images/contact/Contact\ us_mob.webp");
  }
  .contact-banner-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: none;
  }
}