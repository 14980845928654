.tax_main_wrapp2 {
    min-height: 400px;
    height: 523px;
    width: 100vw;
    overflow: hidden;
    position: relative;
    background-image: url("../../assets/images/tax/taxbanner.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: end;
    justify-content: start;
    /* max-width: 1280px; */
    padding-bottom: 8%;
    /* /* max-width: 1280px; */
}
.tax-title {
    width: 720px;
    /* height: 114px; */
    background: #02245bcc;
    /* opacity: 80%; */
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 114px;
    height: auto;
    z-index: 2;
}
.tax-title p{
    font-size: 36px;
    font-weight: 700;
    font-family: var(--font-rubik);
    color: white;
    text-transform: uppercase;

}
.tax_main_wrapp2::after {
    content: "";
    position: absolute;
    top: 0;
    left: -408px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient( to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.73), rgba(0, 0, 0, 0.24), rgba(255, 255, 255, 0) );
    z-index: 1;
}

@media (max-width: 400px) {
    .tax_main_wrapp2 {
        height: 30vh;
        min-height: 200px;
    }
    .tax-title {
        padding: 12px 0;
        min-height: unset;
        height: auto;
        padding: 12px 8px 12px 20px;
        width: 75%;
        justify-content: flex-start;
    }
    .tax-title p {
        font-size: 20px;
    }
}
@media (min-width: 401px) and (max-width:481px){
    .tax_main_wrapp2 {
        height: 30vh;
        min-height: 200px;
    }
    .tax-title {
        padding: 12px 0;
        min-height: unset;
        height: auto;
        padding: 12px 8px 12px 20px;
        width: 75%;
        justify-content: flex-start;
    }
    .tax-title p {
        font-size: 27px;
    }
}
@media (min-width: 481px) and (max-width: 768px) {
    .tax_main_wrapp2 {
        height: 52vh;
        min-height: 200px;
    }
    .tax-title p {
        font-size: 27px;
    }
    .tax-title {
        width: 70%;
        min-height: unset;
        padding: 12px 0;
        height: auto;
    }
}
@media (min-width: 768px) and (max-width:1024px){
    .tax_main_wrapp2 {
        height: 50vh;
        min-height: 200px;
        background-image: url("../../assets/images/tax/taxbanner_blog.webp");
    }
}