.about_nl_main_wrapp {
  width: 90%;
  margin: 10px auto;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-blue);
  border-radius: 12px;
  margin-bottom: 5rem;
}

.nl_first_wrapp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
}

.nl_first_wrapp > h4 {
  font-family: var(--font-base);
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.nl_first_wrapp > p {
  font-family: var(--font-base);
  font-size: 14px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.about_nl_input_wrapp {
  width: 40%;
  padding: 6px;
  border-radius: 12px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about_nl_input_box_wrapp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  width: 77%;
  margin-left: 10px;
}

.about_nl_input_box_wrapp > img {
  width: 1.2rem;
  height: 1.2rem;
}

.about_nl_input_box_wrapp > input {
  width: 80%;
  border: none;
  font-size: 16px;
  color: var(--color-blue);
}

.about_nl_submit_btn {
  background-color: var(--color-sky-blue);
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;

  color: #fff;
  font-family: var(--font-base);
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  transition: box-shadow 0.3s ease-in-out;
}

.about_nl_submit_btn:hover {
  /* border: 3px solid var(--color-sky-blue); */
  box-shadow: 0 0 2px 3px #1ec198a4;
}
.news_letter_form{
  display: flex;
  width: 100%;
}
.news-form{
  width: 100%;
}

@media (max-width: 768px) {
  .about_nl_main_wrapp {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .nl_first_wrapp {
    width: 100%;
  }

  .about_nl_input_wrapp {
    width: 100%;
  }

  .about_nl_input_box_wrapp > input {
    font-size: 14px;
  }
  .news_letter_form {
    justify-content: space-between;
}
}
