.PP_main_div{
    width: 100%;
    max-width: var(--max-width);
    box-shadow: 0px 0px 18.6px 0px #0000001A;
    margin: 2rem auto;
    padding-top: 4rem;
    margin-bottom: 5rem;
}
.PP_title_div{
    background: #02245BCC;
    max-width: 685px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 114px;
    height: auto;
}
.PP_title_div > h2{
    font-size: 36px;
    font-weight: 700;
    font-family: var(--font-rubik);
    color: var(--color-white);
    line-height: 27.36px;
}
.PP_content_div{
    padding: 3.5rem 2.5rem 3.5rem 2.5rem;
}
.PP_content_div > p{
    font-size: 14px;
    line-height: 24px;
    font-family: var(--font-base);
    font-weight: 600;
    color: #444444;
    text-align: justify;
}
.PP_head-bottom {
    height: 4.13px;
    background: #FF8000;
    border: none;
    width: 55.12px;
    border-radius: 8.27px;
    margin-bottom: 23px;
}
.PP_list1{
    margin: 1rem 0;
}
.PP_list1 > li{
    list-style: initial;
    font-size: 14px;
    line-height: 24px;
    font-family: var(--font-base);
    font-weight: 600;
    color: #444444;
    text-align: justify;
    padding-bottom: 7px;
}
.PP_summery > p{
    font-size: 14px;
    line-height: 24px;
    font-family: var(--font-base);
    font-weight: 600;
    color: #444444;
    text-align: justify;
}
.PP_sub_title {
    color: var(--color-blue);
    font-family: var(--font-rubik);
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 53.01px;
}
.PP_summery{
    margin-top: 2rem;
}
.PP_summery > p> a{
    color: #3C3CF2;
    word-break: break-all;
}
.PP_table_Contents >li {
    /* list-style-type: decimal; */
    color: #444444;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    font-family: var(--font-base);
    padding-bottom: 10px;
    text-align: start;
    display: flex;
    align-items: start;
    gap: 16px;
}
.PP_table_Contents >li >p{
    color:  #3C3CF2;
    width: 5%;
    text-align: end;
}
.PP_table_Contents >li >a{
    color:  #3C3CF2;
    text-transform: uppercase;
    width: 95%;
}
.PP_sensitive_info > li >p >a{
    color:  #3C3CF2;
}
.PP_summery> h3{
    color: var(--color-blue);
    font-family: var(--font-rubik);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 53.01px;
    margin: 10px 0;
}

.list-icon {
    width: 20.67px;
    height: 20.67px;
    /* margin-top: 5px; */
}
.PP_list_info{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(30px, auto);
    gap: 20px;
    margin-top: 1rem;
}
.PP_sensitive_info > li {
    font-size: 14px;
    color: #444444;
    font-family: var(--font-base);
    font-weight: 600;
    line-height: 24px;
    padding-bottom: 20px;
    display: flex;
    align-items: start;
    gap: 16px;
    text-align: justify;
}

.PP_table_div{
    font-size: 14px;
    color: #444444;
    font-family: var(--font-base);
    font-weight: 600;
    line-height: 24px;
}
table {
    border-collapse: collapse;
    width: 100%;
  }
  
  th,
  td {
    border: 1.5px solid #D2D2D2;
    text-align: left;
    padding: 14px;
  }
  
  .colored-heading {
    background-color:#F4F4F4;
    font-weight: 700;
    font-size: 18px;
  }
  .col1{
    width: 25%;
  }
  .col2{
    width: 45%;
  }
  .col3{
    width: 20%;
    text-align: center;
  }
  td{
    height: 97px;
    vertical-align: middle;
    /* text-align: justify; */
  }
  .PP_rights_info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(30px, auto);
    gap: 20px;
    margin-top: 1rem;
    }
.PP_summery >h4{
    font-size: 20px;
    color: #02245B;
    font-family: var(--font-rubik);
    font-weight: 500;
    line-height: 50px;
    border-bottom: 1px solid #02245B;
    max-width: max-content;
}
/* .PP_summery >h4:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #02245B; 
    margin-top: 5px; 
  } */

 .PP_tbl_title{
    display: flex;
 } 
.PP_blk_link{
    color: #444444!important;
}
.CC_link{
    color:  #3C3CF2;
}


@media(max-width: 480px){
    .PP_list_info {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .PP_title_div > h2 {
        font-size: 30px;
    }
    .PP_title_div {
    min-height: unset;
    height: auto;
    padding: 22px 8px 22px 20px;
    width: 80%;
    justify-content: flex-start;
    }
    .PP_content_div {
        padding:3.5rem 1rem 3.5rem 1rem;
    }
    .PP_sub_title {
        font-size: 25px;
        line-height: 37.01px;
    }
    .PP_summery> h3 {
        font-size: 20px;
        line-height: 25.01px;
    }
    .PP_rights_info {
        grid-template-columns: repeat(1, 1fr);
    }
    .PP_summery >h4 {
        font-size: 18px;
        line-height: 27px;
        margin: 10px 0;

    }
    .PP_table_div {
        overflow-x: scroll;
    }
}

@media (min-width: 481px) and (max-width:768px){
    .PP_list_info {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .PP_title_div > h2 {
        font-size: 30px;
    }
    .PP_title_div {
    min-height: unset;
    height: auto;
    padding: 22px 8px 22px 20px;
    width: 75%;
    justify-content: flex-start;
    }
    .PP_content_div {
        padding:3.5rem 1rem 3.5rem 1rem;
    }
    .PP_sub_title {
        font-size: 25px;
        line-height: 37.01px;
    }
    .PP_summery> h3 {
        font-size: 20px;
        line-height: 25.01px;
    }
    .PP_rights_info {
        grid-template-columns: repeat(1, 1fr);
    }
    .PP_summery >h4 {
        font-size: 18px;
        line-height: 33px;
    }
}