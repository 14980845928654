.about_hero_main_wrapp {
  height: 523px;
  width: 100vw;
  overflow: hidden;
  position: relative;
  /* background-size: cover; */
  /* background-position: center; */
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.about-banner-img{
  width: 100%;
  height: 700px;
  object-fit: cover;
  margin-top: -17px;
}
.about_hero_main_wrapp::after {
  content: "";
  position: absolute;
  top: 0;
  left: -408px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.73),
    rgba(0, 0, 0, 0.24),
    rgba(255, 255, 255, 0)
  );
  z-index: 1; /* Ensure the overlay is above other content */
}
.about_wave_img1 {
  max-width: 100vw;
  width: 100vw;
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 640px;
}

.about_hero_text_section_wrapp {
  position: absolute;
  width: 100vw;
  left: 0px;
  top: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.about_hero_text_section_inner_wrapp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}
.about_hero_head_div{
  display: flex;
  gap: 29px;
  /* margin-top: 26px; */
  height: 56px;
}
.green_vr {
  width: 15px;
  /* height: 56px; */
  flex-shrink: 0;
  background: #1ec199;
}

/* blog hero section start */

.blog_hero_main_wrapp {
  height: 523px;
  width: 100vw;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.blog-banner-image {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/blog/blog_page-banner2.webp");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  transform: scaleX(-1);
}

/* blog hero section start */

@media (min-width: 401px) and (max-width:768px){
  .about_hero_main_wrapp {
    height: 50vh;
  }
  .about_main_wrapp {
    background-size: contain; /* Adjust background size for smaller screens */
    background-position: start;
  }

  .about_wave_img1 {
    display: none;
  }

  .about_wave_img {
    max-width: 100vw;
    width: 100vw;
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 30vh;
    background-image: url("../../assets/images/herowave_2.png"); /* Replace 'your-image.jpg' with your image URL */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .about_hero_text_section_inner_wrapp {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    /* width: 60%; */
  }

  .des_shadow_text,
  .large_shadow_text {
    text-align: center;
    color: #fff;
    font-size: 40px;
  }

  .des_shadow_text {
    font-size: 38px;
  }

  .des_shadow_text {
    font-size: 16px;
  }

  .blog_hero_main_wrapp {
    height: 50vh;
  }
  .about_hero_text_section_wrapp {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 77%;
    left: 50%;
    transform: translate(-50%, -6%);
    z-index: 1;
  }
  .about-banner-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: -91px;
}
.about_hero_head_div {
  height: 38px;
  gap: 22px;
}
.green_vr {
  width: 10px;
}
}
@media (max-width: 400px) {
  .about_hero_main_wrapp {
    height: 50vh;
  }
  .about_main_wrapp {
    background-size: contain; /* Adjust background size for smaller screens */
    background-position: start;
  }

  .about_wave_img1 {
    display: none;
  }

  .about_wave_img {
    max-width: 100vw;
    width: 100vw;
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 30vh;
    background-image: url("../../assets/images/herowave_2.png"); /* Replace 'your-image.jpg' with your image URL */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .about_hero_text_section_inner_wrapp {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    /* width: 60%; */
  }

  .des_shadow_text,
  .large_shadow_text {
    text-align: center;
    color: #fff;
    font-size: 40px;
  }

  .des_shadow_text {
    font-size: 38px;
  }

  .des_shadow_text {
    font-size: 16px;
  }

  .blog_hero_main_wrapp {
    height: 50vh;
  }
  .about_hero_text_section_wrapp {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 77%;
    left: 50%;
    transform: translate(-50%, -6%);
    z-index: 1;
  }
  .about-banner-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: -91px;
}
.about_hero_head_div {
  height: 38px;
  gap: 22px;
}
.green_vr {
  width: 10px;
}

}
@media (min-width: 769px) and (max-width: 1024px) {
  .about_hero_main_wrapp {
    height: 70vh;
  } 
  .blog_hero_main_wrapp {
    height: 70vh;
  }
  .green_vr {
    width: 10px;
  }
}