.home-gallery {
    width: 100%;
    margin: auto;
    margin-top: 6%;
    position: relative;
  }
  
  .home-gallery-wrap {
    max-width: 1280px;
    margin: auto;
    margin-top: 53px;
  }
  
  .h-g-head {
    width: 65%;
    margin: auto;
  }
  
  .h-g-head .title1 {
    color: #ff5e14;
    text-align: center;
    font-family: var(--font-base);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18.001px;
    text-transform: uppercase;
  }
  
  .h-g-head .title2 {
    color: #02245b;
    text-align: center;
    font-family: var(--font-rubik);
    font-size: 36.001px;
    font-style: normal;
    font-weight: 700;
    line-height: 43.202px;
  }
  
  .gallery-img-div {
    width: 95%;
    margin: auto;
    margin-top: 35px;
  }
  
  .gallery-img-div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1;
  }
  
  @media screen and (max-width: 768px) {
    .h-g-head .title2 {
      font-size: 25px;
      line-height: 34.202px;
    }
    .h-g-head {
      width: 85%;
    }
    .h-g-head .title1 {
      padding-bottom: 10px;
    }
  }
  