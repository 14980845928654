
.home-section1{
    width: 100%;
    /* background-image: url(../../assets/images/home/Home_page_banner-1.webp); */
    height: 82vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    /* animation:change 15s infinite ease-in-out; */
    /* z-index: -1; */
}
.home-sec1-div{
    /* max-width: 1280px; */
    margin: auto;
    height: 82vh;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.home-sec1-content-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 8rem;
    z-index: +1;
}
.home-sec1-div p{
    color: #FFF;
    text-align: center;
    text-shadow: 4px 3px 8.6px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family:var(--font-rubik);
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 125.023%;
    width: 100%;
    text-transform: uppercase;
}
.apply-btn{
    border-radius: 15px;
    background: rgba(30, 193, 153, 0.63);
    backdrop-filter: blur(14.5px);
    width: 280px;
    border: none;
    height: 75px;
    margin-top: 46px;
    color: #FFF;
    color: #FFF;
    font-family: var(--font-base);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: box-shadow 0.3s ease-in-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.apply-btn:hover {
    /* border: 3px solid var(--color-sky-blue); */
    box-shadow: 0 0 2px 3px #1ec198a4;
  }
.mouse-btn{
    border: none;
    background: none;
    margin-top: 20px;
}
.blue-banner-img{
    position: absolute;
    bottom:-63px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 530px;
}
.slider_img1 {
    width: 100%;
    /* height: 544px; */
    position: relative;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.h_banner_img{
    width: 100%;
    height: 82vh;
    object-fit: cover;
  }

@media (max-width: 480px) {
    .home-sec1-div p {
        font-size: 25px;
        padding: 0 7px;
        width: 100%;
    }
    .apply-btn {
        width: 45%;
    }
    .home-sec1-content-div {
        padding-top: 65px;
        /* height: 100vh; */
    }
    .blue-banner-img {
        bottom: 0;
        height: auto;
    }
    .home-section1 {
        height: 50vh;

    }
    .home-sec1-div {
        /* max-width: 1280px; */
        margin: auto;
        height: 50vh;
    }
    .apply-btn {
        /* background-color: rgba(30, 193, 153, 0.80); */
        width: 40%;
        border: none;
        height: 53px;
        font-size: 16px;
        margin-top: 10px;
    }
    .h_banner_img{
        height: 50vh;
      }

}
@media (min-width: 481px) and (max-width:768px){
    .home-sec1-div p {
        font-size: 27px;
        width: 100%;
    }
    .apply-btn {
        width: 45%;
    }
    .home-sec1-content-div {
        padding-top: 20px;
        /* height: 100vh; */
    }
    .blue-banner-img {
        bottom: 0;
        height: auto;
    }
    .home-section1 {
        height: 50vh;

    }
    .home-sec1-div {
        /* max-width: 1280px; */
        margin: auto;
        height: 50vh;
    }
    .apply-btn {
        /* background-color: rgba(30, 193, 153, 0.80); */
        width: 40%;
        border: none;
        height: 57px;
        font-size: 16px;
        margin-top: 10px;
    }
    .h_banner_img{
        height: 50vh;
      }
}


@media (min-width: 768px) and (max-width:1024px){
    .blue-banner-img {
        bottom: -53px;
        height: auto;
    }
    .home-section1 {
        height: 70vh;
    }
    .home-sec1-div {
        /* max-width: 1280px; */
        margin: auto;
        height: 70vh;
    }
}
@media (min-width: 320px) and (max-width:400px){
    .home-sec1-content-div {
        padding-top: 48px;
        /* height: 100vh; */
    }
    .home-sec1-div p {
        font-size: 24px;
        padding: 0 7px;
    }
    .apply-btn {
        /* background-color: rgba(30, 193, 153, 0.80); */
        width: 35%;
        height: 48px;
    }
}
.home_banner_slider .slick-slide {
    opacity: 0;
    transition: opacity 3s ease-in-out!important; /* Adjust the duration as needed */
  }
  
  .home_banner_slider .slick-slide.slick-active {
    opacity: 1;
  }


  
  
