.home-section6{
    max-width: 1280px;
    margin: auto;
    /* margin-top: 70px; */
    margin-bottom: 4rem;
}
.home-sec6-wrap{
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
}
.h-sec6-slider{
    display: flex;
    flex-direction: row;
    gap: 66px;
    justify-content: center;
    width: 100%;
    /* height: 446px; */
}
.h-sec6-slider-div{
    width:90% ;
}
.student-cards{
    /* width: calc(33% - 14px); */
    /* width: 348px !important; */
    /* height: 463px; */
    height: 484px;
    flex-shrink: 0;
    position: relative;
    transition: 0.5s;
    cursor: pointer;
    overflow: hidden;
    /* padding: 14px; */
    padding: 9px;
}
.student-img{
    width: 100%;
}
.student-name{
    color: #FFF;
    font-family: var(--font-base);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 27px;

}
.student-location{
    color: #FFF;
    font-family: var(--font-base);
    font-size: 14px;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
}
.student-response{
    color: #FFF;
    font-family: var(--font-base);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.685px; /* 183.467% */
    padding-top: 10px;
    height: 123px;
    text-align: justify;
}
.student-readmore p{
    color: #FFF;
    font-family: var(--font-base);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 27.359px; /* 227.988% */
    text-transform: capitalize;
    position: relative;
    padding-left: 25px;
}
.student-readmore{
    padding-left: 0px;
    margin-top: 12px;
}
.student-readmore p::before {
    content: " ";
    display: block;
    width: 16px;
    height: 3px;
    background: #FFF;
    position: absolute;
    left: 0;
    bottom: 40%;
}
.student-card1-des{
    position: relative;
    z-index: 3;
    width: 100%;
    padding: 0 20px 15px 20px;
    bottom: 57px;
    height: 253px;
}
.student-card2-des{
    position: relative;
    z-index: 3;
    width: 100%;
    /* padding: 0 20px 15px 20px; */
    padding: 0 20px 15px 20px;
    /* bottom: 50px;
    height: auto; */
    bottom: 59px;
    height: 249px;
}
.imgg{
    position: absolute;
    height: 100%;
    left: 0;
    width: 100%;
    z-index: -1;
}
.student-card3-des{
    position: relative;
    z-index: 3;
    width: 100%;
    padding: 0 20px 15px 20px;
    /* bottom: 50px;
    height: auto; */
    bottom: 59px;
    height: 249px;
}
.student-card4-des{
    position: relative;
    z-index: 3;
    width: 100%;
    padding: 0 20px 15px 20px;
    /* bottom: 50px;
    height: auto; */
    bottom: 59px;
    height: 249px;
}
.student-card5-des{
    position: relative;
    z-index: 3;
    width: 100%;
    padding: 0 20px 15px 20px;
    /* bottom: 50px;
    height: auto; */
    bottom: 59px;
    height: 249px;
}

.h-sec6-slider .slick-dots li.slick-active button:before {
    color: #FF5E14 !important;
    font-size: 15px !important;
    /* margin-top: 10px; */
   }
   .h-sec6-slider .slick-dots li button:hover:before, .slick-dots li button:focus:before {
    opacity: 1;
    color: #FF5E14 !important;
  } 
   .h-sec6-slider .slick-dots {
    bottom: -48px !important;
    z-index: 1;
    display: block !important;
    cursor: pointer;
    width: 80%;
    }
   .button-slider{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: auto;
    justify-content: center;
    align-items: center;
   }
   .slider-btn-div{
    width: 5%;
   }
   .slider-btn-div button{
    border: none;
    background: transparent;
    cursor: pointer;
  }
  .slider-btn-div2{
    width: 5%;
    text-align: center;
   }
   .slider-btn-div2 button{
    border: none;
    background: transparent;
    cursor: pointer;
  }
  .student-img img{
    width: 100%;
  }
  .slick-prev, .slick-next {
    display: none!important;
  }

  .slider-btn-review{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }
  @media screen and (max-width: 480px) {
    .home-sec6-wrap{
        width: 95%;
        margin: auto;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: -64px;
    } 
    .student-cards{
        width: 100% !important;
        height: unset;
    }
    .slider-btn-div{
        width: 10%;
        display: flex;
        justify-content: end;
   }
    .h-sec6-slider-div {
        width: 90%;
    }
    .slider-btn-div2{
        width: 10%;
        display: flex;
        justify-content: start;
    }
       .h-sec6-slider{
        height: unset;
    }
       .slick-prev,.slick-next{
        display: none !important;
   }
       .student-card1-des {
        bottom: 75px;
        height: 257px;
    }
       .student-card2-des {
        bottom: 73px;
        /* height: 235px; */
        
    
    }
    .student-card2-des::before {
        height: 194px;
    }
       .student-card3-des {
        bottom: 75px;
        /* height: 235px; */
    }
    .student-card4-des {
        bottom: 75px;
        /* height: 235px; */
    }
    .student-card5-des {
        bottom: 75px;
        /* height: 235px; */
    }
    .h-sec6-slider .slick-dots {
        bottom: 24px !important;
        /* z-index: -1; */
        display: block !important;
    }
    .slider-btn-review {
        transform: translateY(-58px);
        margin-top: 0;
    }
    .student-response {
        font-size: 12px;
        text-align: justify;
    }
    .student-name {
        padding-top: 45px;
    }
    .student-card3-des {
        padding: 0 20px 15px 20px;
    }
    .student-readmore {
        margin-top: 0px;
    }
   
    .home-section6 {
        margin-bottom: 0;
    }
  }
  @media (min-width: 481px) and (max-width:768px){
    .home-sec6-wrap{
        width: 380px;
        margin: auto;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: -64px;
    } 
    .student-cards{
        width: 100% !important;
        height: unset;
    }
    .slider-btn-div{
        width: 10%;
        display: flex;
        justify-content: end;
   }
    .h-sec6-slider-div {
        width: 90%;
    }
    .slider-btn-div2{
        width: 10%;
        display: flex;
        justify-content: start;
    }
       .h-sec6-slider{
        height: unset;
    }
       .slick-prev,.slick-next{
        display: none !important;
   }
       .student-card1-des {
        bottom: 75px;
        height: 257px;
    }
       .student-card2-des {
        bottom: 73px;
        /* height: 235px; */
        
    
    }
    .student-card2-des::before {
        height: 194px;
    }
       .student-card3-des {
        bottom: 75px;
        /* height: 235px; */
    }
    .student-card4-des {
        bottom: 75px;
        /* height: 235px; */
    }
    .student-card5-des {
        bottom: 75px;
        /* height: 235px; */
    }
    .h-sec6-slider .slick-dots {
        bottom: 24px !important;
        /* z-index: -1; */
        display: block !important;
    }
    .slider-btn-review {
        transform: translateY(-58px);
        margin-top: 0;
    }
    .student-response {
        font-size: 12px;
        text-align: justify;
    }
    .student-name {
        padding-top: 45px;
    }
    .student-card3-des {
        padding: 0 20px 15px 20px;
    }
    .student-readmore {
        margin-top: 0px;
    }
   
   
  }

  @media (min-width: 769px) and (max-width:1024px){
    .h-sec6-slider {
        
    }
    .student-card2-des {
        height: 250px;
        bottom: 72px;
        /* height: auto; */
    }
    .student-card3-des {
        height: 250px;
        bottom: 72px;
        /* height: auto; */
    }
    .student-card1-des {
        bottom: 69px;
        height: 253px;
        /* height: auto; */
    }
    .student-card4-des {
        bottom: 72px;
        height: 250px;
        /* height: auto; */
    }
    .student-card5-des {
        bottom: 72px;
        height: 250px;
        /* height: auto; */
    }
    .h-sec6-slider .slick-dots {
        bottom: -37px !important;
        z-index: -1;
        display: block !important;
    }
    .slider-btn-review {
        margin-top: 0;
    }
    .student-response {
        font-size: 11px;
        height: 109px;
    }

  }
  @media (min-width: 1025px) and (max-width:1211px){
    .student-response {
        font-size: 13px;
        line-height: 19.685px;
    }
    .h-sec6-slider {
        height: 460px;
    }
    .student-card2-des {
        height: 250px;
        bottom: 72px;
        /* height: auto; */
    }
    .student-card3-des {
        height: 250px;
        bottom: 72px;
        /* height: auto; */
    }
    .student-card1-des {
        bottom: 68px;
        height: 251px;
        /* height: auto; */
    }
    .student-card4-des {
        bottom: 72px;
        height: 250px;
        /* height: auto; */
    }
    .student-card5-des {
        bottom: 72px;
        height: 250px;
        /* height: auto; */
    }
    .h-sec6-slider .slick-dots {
        bottom: -30px !important;
    }
    .slider-btn-review {
        margin-top: 0;
    }
    .student-card1-des::before{
        background-position: center center;
    }
    .student-card2-des::before{
        background-position: center center;
    }
    .student-card3-des::before{
        background-position: center center;
    }
    .student-card4-des::before{
        background-position: center center;
    }
    .student-card5-des::before{
        background-position: center center;
    }
  }