.ahch_main_wrapp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  margin: 10px auto;
  margin-top: 32px;
}

.how_can_help_images_side_wrapp {
  position: relative;
  width: 46%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100vh;
}

.how_can_help_first_img {
  width: 100%;
  height: 80%;
  object-fit: contain;
}

.how_can_help_secound_img {
  position: absolute;
  width: 40%;
  height: 47%;
  bottom: 9%;
  right: 0%;
  border: 10px solid #fff;
  padding: 0px;
}

.how_can_help_secound_img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.how_can_help_text_side_wrapp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 51%;
}

.how_can_help_text_side_part1 {
  width: 100%;
  transform: translate(-12%);
}

.how_can_help_text_side_part2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin: 20px auto;
}

.how_can_help_text_side_part2_first {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.how_can_help_text_side_part2_first_1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
}

.how_can_help_text_side_part2_first_1 > img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.how_can_help_text_side_part2_first_1 > p {
  font-family: var(--font-base);
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-blue);
}

.how_can_help_text_side_part2_first > p {
  font-family: var(--font-base);
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: justify;
  color: #6f6b80;
  width: 88%;
  /* margin: 10px 0px; */
}
.about_book_btn{
  background-color: var(--color-sky-blue);
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  color: #fff;
  font-family: var(--font-base);
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  transition: box-shadow 0.3s ease-in-out;
  margin-left: 76px;
  min-width: 244px;
}
.about_book_btn:hover {
  /* border: 3px solid var(--color-sky-blue); */
  box-shadow: 0 0 2px 3px #1ec198a4;
}
@media (max-width: 768px) {
  .ahch_main_wrapp {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .how_can_help_images_side_wrapp,
  .how_can_help_text_side_wrapp {
    width: 100%;
    padding: 0px 10px;
    margin-top: -61px;
  }

  .how_can_help_text_side_part1 {
    transform: translate(0%);
  }

  .how_can_help_text_side_part2_first_1 > p {
    font-size: 1rem;
  }
  .how_can_help_secound_img {
    position: absolute;
    width: 234px;
    height: 289px;
    bottom: 18%;
    right: 5%;
  }
  .how_can_help_text_side_part2_first_1 > img {
    width: 38px;
    height: 38px;
    object-fit: contain;
}
.about_book_btn_div{
  text-align: center;
  width: 100%;
}
.about_book_btn{
margin-left: 0;
}
}

@media (min-width: 769px) and (max-width:1024px){
  .aboutweare_inner_wrapp {
    height: 63vh;
}
.how_can_help_secound_img {
  width: 58%;
  height: 41%;
  bottom: 7%;
  right: 0%;
  border: 10px solid #fff;
  /* padding: 0px; */
}
.how_can_help_text_side_part1 {
  transform: translate(-5%);
}
.about_book_btn{
  margin-left: 45px;
  }

}