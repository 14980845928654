.test2-section3{
    width: 100%;
    margin-top: 9%;
}
.test2-sec3-wrap{
    max-width: 1280px;
    margin: auto;
    padding-left: 50px;
    padding-right: 50px;
}
.review-slider{
    width: 88%;
    margin: auto;
}
.test2-sec3-div{
    display: flex!important;
    flex-direction: row;
    width: 100%;
    margin: auto;
    gap: 42px;
    padding: 3px
}
.test2-sec3-div .img-div{
    width: 35%;
}
.test2-sec3-div .img-div img{
    width: 100%;
    height: 349px;
    object-fit: cover;
}
.test2-sec3-div .contents{
    width: 65%;
}
.quotes-div{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: flex-end;
    position: relative;
}
.quotes-div img{
    width: 118px;
    position: absolute;
    top: -3px;
    left: -15px;
}
.quotes-div p{
    color: #000;
    font-family: var(--font-base);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 45.685px; /* 190.356% */
    position: relative;
    /* top: 68px; */
    top: 55px;
    height: 185px;
    text-align: justify;
}
.quotes-div2{
    padding-top: 74px;
}
.contents .student-name{
    color: #02245B;
    font-family: var(--font-base);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.contents .student-location{
    color: #02245B;
    font-family: var(--font-base);
    font-size: 20px;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 15px;
}
.contents .student-review{
    color: #414141;
    text-align: justify;
    font-family: var(--font-base);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 175.023%; /* 24.503px */
    margin-bottom: 13px;
}

@media screen and (max-width: 768px) {
    .test2-sec3-wrap {
        max-width: 90%;
        margin: auto;
        padding-left: 0px;
        padding-right: 0px;
    }
    .test2-sec3-div {
        display: flex!important;
        flex-direction: column;
        /* gap: 0; */
    }
    
    .test2-sec3-div .img-div {
        width: 100%;
    }
    .test2-sec3-div .contents {
        width: 100%;
    }
    .quotes-div p {
        font-size: 18px;
        line-height: 33.685px;
        top: 7px;
        height: auto;
    }
    .quotes-div img {
        width: 85px;
        position: absolute;
        top: -30px;
        left: 2px;
    }
    .quotes-div2 {
        padding-top: 0px;
    }
    .contents .student-name {
        font-size: 27px;
    }
    .test2-sec3-div .img-div img {
        height: auto;
    }
    .review-slider {
        width: 100%;
    }
}
@media (min-width: 769px) and (max-width:1024px){
    .test2-sec3-wrap {
        max-width: 85%;
        margin: auto;
        padding-left: 0px;
        padding-right: 0px;
    }
    .test2-sec3-div {
        display: flex!important;
        flex-direction: column;
        /* gap: 0; */
    }
    .quotes-div p {
        font-size: 20px;
        line-height: 41.685px;
        height: auto;
    }
    
    .test2-sec3-div .img-div {
        width: 70%;
        margin: auto;
    }
    .test2-sec3-div .contents {
        width: 100%;
    }
    .quotes-div img {

        left: 2px;
    }
    .quotes-div2 {
        padding-top: 34px;
    }
}
@media (min-width: 1025px) and (max-width:1211px){
    .quotes-div p {
        font-size: 19px;
        line-height: 40.685px;
        height: 133px;
    }
    .test2-sec3-div .img-div img {
        height: 295px;
    }
}