.contact-section2{
    width: 100%;
    margin-top: 4rem;
    /* height: 100vh; */
    margin-bottom: 5rem;

}
.contact-main-wrap{
    max-width: 1280px;
    margin: auto;
    padding-right: 50px;
    /* margin-top: 7%; */
}
.contact-img-div{
    position: relative;
    /* left: auto; */
    display: flex;
    justify-content: end;
    /* padding-right: 50px; */
    /* max-width: 1280px; */
    margin: auto;
}
.contact-img {
    width: 66%;
    position: relative;
    z-index: -1;
}
.contact-ways{
    padding: 50px;
    position: absolute;
    top: 23%;
    background: white;
    width: 50%;
    /* height: 98%; */
    left: 0px;
    
box-shadow: 0px 0px 10px 5px rgba(173, 173, 173, 0.10);
}
.contact-main{
    /* padding-top: 50px; */
}
.con-sec2-title{
    color: #FF5E14;
    font-family:var(--font-base);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18.001px; /* 112.504% */
    text-transform: uppercase;
    margin-bottom: 12px;
}
.con-sec2-des1{
    color: #02245B;
    font-family: var(--font-rubik);
    font-size: 36.001px;
    font-style: normal;
    font-weight: 700;
    line-height: 43.202px;
    width: 76%;
}
.con-sec2-des2{
    color: rgba(0, 0, 0, 0.65);
    font-family: var(--font-base);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px; /* 168.75% */
    letter-spacing: 0.6px;
    text-align: justify;
}
.con-section2-2{
    margin-top: 10%;
}

.email{
    color: #000;
    font-family: var(--font-base);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17.191px; /* 122.796% */
    text-transform: capitalize;
}
.content{
    color: #000;
    font-family: var(--font-base);
    font-size: 13.33px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.001px; /* 135.039% */
}
.contact-sec2-email,
.contact-sec2-mob,
.contact-sec2-skype {
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.72);
    margin-bottom: 28px;
    padding: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contact-sec2-email {
    border: 1.5px dashed #FF5E14;
}

.contact-sec2-mob {
    border: 1.5px dashed #1EC199;
}

.contact-sec2-skype {
    border: 1.5px dashed #02245B;
}

.contact-sec2-email .circle,
.contact-sec2-mob .circle,
.contact-sec2-skype .circle {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    margin-right: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-sec2-email .circle {
    border: 2px solid #FF5E14;
}

.contact-sec2-mob .circle {
    border: 2px solid #1EC199;
}

.contact-sec2-skype .circle {
    border: 2px solid #02245B;
}

.circle img{
    width: 65%;
}
.circle{
    cursor: pointer;
}
.content a{
    text-decoration: none;
    color: #000;
    font-family: var(--font-base);
    font-size: 13.33px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.191px; /* 128.968% */
    text-transform: capitalize;
}
.main-content{
    cursor: pointer;
}
.content a{
    text-transform: lowercase;
}
.icon_a{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (max-width: 768px) {
    .contact-main-wrap {
        padding-right: 0;
    }
    .contact-img-div {
        justify-content: center;
    position: unset;
    flex-direction: column;
    }
    .contact-img{
        width: 100%;
    }
    .contact-ways {
        width: 90%;
        height: auto;
        left: auto;
        padding: 30px;
        margin: auto;
        margin-top: -64px;
        position: unset;
    }
    .con-sec2-des1 {
        font-size: 23.001px;
        line-height: 32.202px;
        width: 100%;
    }
    .con-sec2-des2 {
        font-size: 13px;
        line-height: 23px;

    }
    .con-section2-2 {
        margin-top: 5%;
    }
  }

  /* tablet view  */
@media (min-width: 768px) and (max-width: 1024px){ 
    .contact-main-wrap {
        padding-right: 0;
    }
    .contact-img-div {
        justify-content: center;
    position: unset;
    flex-direction: column;
    }
    .contact-img{
        width: 100%;
    }
    .contact-ways {
        width: 85%;
        height: auto;
        left: auto;
        padding: 35px;
        margin: auto;
        margin-top: -64px;
        position: unset;
    }
    .con-sec2-des1 {
        font-size: 28.001px;
        line-height: 32.202px;
        width: 100%;
    }
    .con-sec2-des2 {
        font-size: 13px;
        line-height: 23px;
    }
    .con-section2-2 {
        margin-top: 5%;
    }
} 

@media (min-width: 1025px){
    .contact-section2{
        height: 750px;
    }
}