.nav_main_wrapp {
  display: flex;
  align-items: center;
  background: var(--color-white);
  width: 100%;
  /* min-width: 100vw; */
  gap: 15%;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 11;
}

.nav_base_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 1rem 0; */
  padding: 10px 0;
  width: 100%;
}

.nav_logo {
  /* max-width: 140px; */
  max-width: 84px;
  width: 100%;
  height: auto;
}

.nav_links {
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 3rem; */
  /* gap: 2rem; */
  gap: 29px;
}

.nav_links > a {
  color: var(--color-black);
  font-weight: 400;
  /* font-size: 1rem; */
  font-size: 14px;
}

.nav_myacc_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-left: 1rem;
  position: relative;
}

.nav_profile {
  width: 26.5px;
  height: 25.5px;
}

.sidebar_logo {
  display: none;
}

.nav_sidebar_wrapp {
  background: rgb(255, 255, 255);
  flex-direction: column;
  position: fixed;
  right: 0px;
  top: 84px;
  display: none;
  /* backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.navbar_inner_wrapp_1 {
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 1rem;
  margin: auto;
}
.navbar_inner_wrapp {
  display: flex;
      /* align-items: center; */
      justify-content: center;
      width: auto;
      height: auto;
      flex-direction: column;
      padding: 1rem;
      /* margin: auto; */
      /* margin-left: 18%; */
      margin: 0 auto;
}

.navbar_inner_wrapp > a {
  font-size: 18px;
  font-family: var(--font-base);
  font-weight: 600;
  color: black;
  margin-bottom: 10px;

}

.navbar_inner_wrapp_2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem auto;
  gap: 1rem;
  flex-wrap: wrap;
}

.nav_links_apply_wrapp {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* width: 20%; */
  gap: 1rem;
}

.nav_blue_btn {
  background-color: var(--color-blue);
  font-size: 16px;
  font-family: var(--font-base);
  color: var(--color-white);
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  height: 40px;
  text-align: center;
}
.side_nav_blue_btn {
  background-color: var(--color-blue);
  font-size: 16px;
  font-family: var(--font-base);
  color: var(--color-white);
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  height: 40px;
  width: 12rem;
  text-align: center;
}
.nav_blue_btn:hover {
  box-shadow: 0 0 2px 3px #02245b7c;
}

.nav_select_wrapp {
  width: 4rem;
  height: 36px;
}

.selected_option {
  color: #000;
  font-family: var(--font-base);
  font-size: 14px;
  font-weight: 600;
  border-radius: 6px;
}

.accunt_sec_wrapp {
  width: 100%;
  background: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
}

.accunt_sec_wrapp > a {
  padding: 1rem;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  text-align: right;
  color: var(--color-black);
  cursor: pointer;
  /* background: var(--color-orange); */
}

.accunt_sec_wrapp > a:hover {
  font-weight: bold;
  color: var(--color-orange);
}

.radio-btn-flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;
}

.radio-btn-inner-flex {
  display: flex;
  gap: 5px;
}

.brand-lable-radio-btn-txt {
  font-size: 14px;
  color: var(--color-black);
  font-weight: 300;
}

.sign_input_wrapp_padding_less {
  margin-bottom: 0.5rem !important;
}
.webinar-menu {
  color: black;
  font-weight: 600;
}

.webinar-menu.active {
  color: var(--color-sky-blue);
}

.webinar-menu.green {
  color: var(--color-sky-blue);
}



/* Account menu open css start */

.navbar-acc-menu-open-warapp {
  width: 230px;
  min-height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: absolute;
  top: 50px;
  right: -30px;
  z-index: 999;
  padding: 20px;
}

.navbar-acc-menu-link {
  font-size: 14px;
  color: var(--color-black);
  font-weight: 400;
}

.navbar-acc-menu-link:hover {
  font-weight: 600;
  color: var(--color-orange);
}

/* sidebar icon start */

#checkbox {
  display: none;
}

.toggle {
  display: none;
}

.bars {
  width: 100%;
  height: 4px;
  background-color: var(--color-blue);
  border-radius: 4px;
}

#bar2 {
  transition-duration: 0.8s;
}

#bar1 {
  width: 50%;
}

#bar2 {
  width: 75%;
}

#checkbox:checked + .toggle .bars {
  position: absolute;
  transition-duration: 0.5s;
}

#checkbox:checked + .toggle #bar2 {
  transform: scaleX(0);
  transition-duration: 0.1s;
}

#checkbox:checked + .toggle #bar1 {
  width: 100%;
  transform: rotate(45deg);
  transition-duration: 0.5s;
}

#checkbox:checked + .toggle #bar3 {
  width: 100%;
  transform: rotate(-45deg);
  transition-duration: 0.5s;
}

#checkbox:checked + .toggle {
  transition-duration: 0.5s;
  transform: rotate(180deg);
}

/* sidebar icon end */

@media (max-width: 1240px) {
  .nav_links_apply_wrapp {
    width: 18%;
  }

  .nav_links {
    gap: 2rem;
  }
}

@media (max-width: 900px) {
  .toggle {
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 7px;
    transition-duration: 0.5s;
  }
  .sidebar_logo {
    display: block;
  }

  .nav_links,
  .nav_links_apply_wrapp {
    display: none;
  }
  .webinar-menu {

    font-size: 18px;
}
  .nav_sidebar_wrapp {
    background: rgb(255, 255, 255);
    flex-direction: column;
    position: fixed;
    right: 0px;
    top: 83px;
    display: block;
    /* backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  }
    .nav_base_wrapp {

      padding: 1rem;
  }
  .nav_sidebar_wrapp > a {
    padding: 1rem;
    border-bottom: 1px solid #c1c1c1;
    width: 100%;
    font-size: 18px;
    font-weight: 300;
    text-align: right;
    color: var(--color-black);
    cursor: pointer;
    /* background: var(--color-orange); */
  }

  .nav_sidebar_wrapp > a:hover {
    font-weight: bold;
    color: var(--color-white);
    background: var(--color-orange);
  }
  .navbar_inner_wrapp > a {
    width: max-content;
}
.navbar_inner_wrapp_2 {
  margin: 0 auto;
  flex-direction: column;
  gap: 1.5rem;
}
.nav_logo {
  /* max-width: 140px; */
  max-width: 76px;
}



}
@media (min-width: 901px) and (max-width: 1240px) {
  .nav_blue_btn {
    font-size: 12px;
  }
  .nav_links {
    gap: 1.5rem;
  } 
}





.nav_dropdown_wrapper {
  position: relative;
  font-size: 14px;
}

.nav_dropdown_content {
  display: block;
  position: absolute;
  top: 61px;
  left: -59px;
  background-color: white;
  border-radius: 3px;
  /* border-top: none; */
  z-index: 1;
  width: 201px;
  height: 252px;
  box-shadow: 0px 0px 5px 0px #0000001A;
  text-align: center;
  border: 1px solid #E6E6E6
}
.nav_dropdown_content:after {
	bottom: 99%;
	left: 44%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-bottom-color: white;
	border-width: 12px;
  z-index: -1;
}

.nav_dropdown_content a {
  display: block;
  padding: 10px 20px;
  color: black;
  text-decoration: none;
  font-size: 12px;
    font-weight: 600;
    font-family: var(--font-base);
    line-height: 19.07px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav_dropdown_content a:hover {
  background-color: #f0f0f0;
}
.nav_drop_line{
  color: #E6E6E6;
}

.nav_dropdown_title {
  cursor: pointer;
}

.nav_dropdown_content_side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  margin-left: 1rem;
}

.nav_dropdown_content_side a {
  display: block;
  padding-bottom: 7px;
  color: black;
  text-decoration: none;
  font-family: var(--font-base);
  font-size: 14px;
  font-weight: 600;
  opacity: 70%;
}

.nav_dropdown_content_side a:hover {
  background-color: #f0f0f0;
}

.nav_drop_line {
  width: 100%;
  margin: 0;
  border: 0;
  border-top: 1px solid #ddd;
}
