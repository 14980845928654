.home-question {
    width: 100%;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5rem;
  }
  
  .home-question-wrap {
    max-width: 1280px;
    padding-left: 50px;
    padding-right: 50px;
    margin: auto;
  }
  
  .home-question-div {
    display: flex;
    flex-direction: row;
    gap: 85px;
  }
  
  .home-question-sec1 {
    width: 40%;
    display: flex;
  }
  
  .home-question-sec2 {
    width: 60%;
  }
  
  .home-question-sec1 .contents {
    padding-top: 37%;
  }
  
  .contents .head {
    position: relative;
    width: 89%;
    height: 143px;
  }
  
  .contents .title {
    color: #02245b;
    font-family: var(--font-rubik);
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 54px;
    position: relative;
  }
  
  .contents .des {
    color: rgba(0, 0, 0, 0.65);
    font-family: var(--font-base);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px;
    padding-top: 30px;
    width: 83%;
    text-align: justify;
  }
  
  .question-img {
    position: absolute;
    right: 0;
    top: -38px;
    width: 48%;
  }
  
  .accordian-main {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border-top: 1px solid rgba(30, 193, 153, 0.56);
  }
  
  .accordian-main:first-child {
    border-top: none;
  }
  
  .title-btn-div {
    display: flex;
    width: 100%;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  
  .title-btn-div .title {
    width: 90%;
  }
  
  .title-btn-div .title p {
    color: #02245b;
    font-family: var(--font-base);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    width: 95%;
  }
  
  .title-btn-div .btn {
    width: 10%;
  }
  
  .title-btn-div .btn button {
    border: none;
    background: 0 0;
    -webkit-transition-duration: .3s;
    transition: transform .3s ease;
    transition-duration: .3s;
    -webkit-transition-property: transform;
  }
  
  .btn button:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  
  .accordian-del-p {
    color: rgba(2, 36, 91, 0.7);
    font-family: var(--font-base);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px;
    width: 80%;
    padding-bottom: 34px;
    text-align: justify;
  }
  
  .data-p {
    width: 100%;
  }
  
  .btn>button>img {
    width: 42px;
    height: 42px;
  }
  
  .blue-link-acc {
    color: #00f;
  }
  
  @media screen and (max-width: 480px) {
    .home-question-wrap {
      max-width: 1280px;
      padding-left: 0;
      padding-right: 0;
      margin: auto;
      width: 90%;
    }
    .home-question-div {
      flex-direction: column;
      gap: 20px;
    }
    .home-question-sec1 {
      width: 100%;
    }
    .home-question-sec2 {
      width: 100%;
    }
    .accordian-del p {
      width: 100%;
      text-align: justify;
    }
    .title-btn-div .title p {
      color: #02245b;
      font-family: var(--font-base);
      font-size: 17px;
    }
    .contents .head {
      position: unset;
      width: 100%;
      height: 122px;
      display: flex;
    }
    .question-img {
      position: unset;
      width: 130px;
      height: 100px;
      transform: translateY(-31px);
    }
    .contents .title {
      color: #02245b;
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      position: relative;
      z-index: 1;
    }
    .contents .des {
      padding-top: 0;
      width: 100%;
      text-align: justify;
    }
    .home-question-sec1 .contents {
      padding-top: 0;
    }
  }
  
  @media (min-width: 480px) and (max-width: 768px) {
    .home-question-wrap {
      max-width: 1280px;
      padding-left: 0;
      padding-right: 0;
      margin: auto;
      width: 90%;
    }
    .home-question-div {
      flex-direction: column;
      gap: 20px;
    }
    .home-question-sec1 {
      width: 100%;
    }
    .home-question-sec2 {
      width: 100%;
    }
    .accordian-del p {
      width: 100%;
      text-align: justify;
    }
    .title-btn-div .title p {
      color: #02245b;
      font-family: var(--font-base);
      font-size: 17px;
    }
    .contents .head {
      position: relative;
      width: 100%;
      height: 143px;
    }
    .question-img {
      position: absolute;
      right: 0;
      top: -29px;
      width: 130px;
    }
    .contents .title {
      color: #02245b;
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      position: relative;
      z-index: 1;
    }
    .contents .des {
      padding-top: 0;
      width: 100%;
      text-align: justify;
    }
    .home-question-sec1 .contents {
      padding-top: 0;
    }
  }
  
  @media (min-width: 768px) and (max-width: 1024px) {
    .home-question-wrap {
      max-width: 1280px;
      padding-left: 0;
      padding-right: 0;
      margin: auto;
      width: 85%;
    }
    .home-question-div {
      flex-direction: column;
      gap: 20px;
    }
    .home-question-sec1 {
      width: 100%;
    }
    .home-question-sec2 {
      width: 100%;
    }
    .accordian-del p {
      width: 85%;
      text-align: justify;
    }
    .title-btn-div .title p {
      color: #02245b;
      font-family: var(--font-base);
      font-size: 17px;
    }
    .contents .head {
      position: relative;
      width: 70%;
      height: 143px;
    }
    .question-img {
        position: absolute;
        right: 0;
        top: -24px;
        width: 130px;
        left: 24rem;
    }
    .contents .title {
      color: #02245b;
      font-family: var(--font-800);
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      position: relative;
    }
    .contents .des {
      padding-top: 0;
      width: 100%;
    }
    .home-question-sec1 .contents {
      padding-top: 40px;
    }
  }
  
  @media (min-width: 1025px) and (max-width: 1211px) {
    .home-question-div {
      gap: 24px;
    }
    .contents .head {
      width: 100%;
    }
    .contents .title {
      font-size: 31px;
      line-height: 43px;
    }
    .question-img {
      top: -32px;
      width: 167px;
    }
  }
  
  .accordian-del {
    max-height: 0;
    overflow: hidden;
    transition: max-height .3s ease-out;
  }
  
  .accordian-del.open {
    max-height: 1000px;
  }
  
  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideUp {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
  
  .accordian-del.open {
    animation: slideDown .3s ease-out;
  }
  
  .accordian-del {
    animation: slideUp .3s ease-out;
  }
  
  @keyframes slideUp1 {
    0% {
      max-height: 1000px;
    }
    100% {
      max-height: 0;
    }
  }
  
  .accordian-del.closed {
    animation: slideUp1 .5s ease forwards;
  }
  