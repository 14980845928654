.program2_main_wrapp {
  min-height: 400px;
  height: 523px;
  width: 100vw;
  overflow: hidden;
  position: relative;
  background-image: url("../../assets/images/programs/program-banner2.webp"); /* Replace 'your-image.jpg' with your image URL */
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  display: flex;
  align-items: end;
  justify-content: start;
  /* max-width: 1280px; */
  padding-bottom: 4%;
  /* max-width: 1280px; */
}
.program2_main_wrapp2 {
  min-height: 400px;
  height: 523px;
  width: 100vw;
  overflow: hidden;
  position: relative;
  background-image: url("../../assets/images/programs/Professional\ Career\ Training.webp"); /* Replace 'your-image.jpg' with your image URL */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: end;
  justify-content: start;
  /* max-width: 1280px; */
  padding-bottom: 4%;
  /* max-width: 1280px; */
}
.program2_main_wrapp3 {
  min-height: 400px;
  height: 523px;
  width: 100vw;
  overflow: hidden;
  position: relative;
  background-image: url("../../assets/images/programs/Virtual\ Internship.webp"); /* Replace 'your-image.jpg' with your image URL */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: end;
  justify-content: start;
  /* max-width: 1280px; */
  padding-bottom: 4%;
  /* max-width: 1280px; */
}
.program2_main_wrapp4 {
  min-height: 400px;
  height: 523px;
  width: 100vw;
  overflow: hidden;
  position: relative;
  background-image: url("../../assets/images/programs/STEP.webp"); /* Replace 'your-image.jpg' with your image URL */
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  display: flex;
  align-items: end;
  justify-content: start;
  /* max-width: 1280px; */
  padding-bottom: 4%;
  /* max-width: 1280px; */
}
.program2_main_wrapp5 {
  min-height: 400px;
  height: 523px;
  width: 100vw;
  overflow: hidden;
  position: relative;
  background-image: url("../../assets/images/programs/Au_Pair_banner.webp"); /* Replace 'your-image.jpg' with your image URL */
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  display: flex;
  align-items: end;
  justify-content: start;
  /* max-width: 1280px; */
  padding-bottom: 4%;
  /* max-width: 1280px; */
}
.programP_main_wrapp {
  min-height: 400px;
  height: 523px;
  width: 100vw;
  overflow: hidden;
  position: relative;
  background-image: url("../../assets/images/programs/pannionia_banner.webp"); /* Replace 'your-image.jpg' with your image URL */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: end;
  justify-content: start;
  /* max-width: 1280px; */
  padding-bottom: 4%;
  /* max-width: 1280px; */
}
.program2_main_wrapp > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.program-title {
  width: 720px;
  background: #02245bcc;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 94px;
  height: auto;
}
.program-title > p {
  font-family: var(--font-rubik);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
}
.programP-title {
  width: 79%;
  background: #02245bcc;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  min-height: 94px;
  height: auto;
  padding-left: 15%;

}
.programP-title > p {
  font-family: var(--font-rubik);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
}
@media (max-width: 400px) {
  .program2_main_wrapp {
    height: 30vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
  .program2_main_wrapp2 {
    height: 30vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
  .program2_main_wrapp3 {
    height: 30vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
  .program2_main_wrapp4 {
    height: 30vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
  .program2_main_wrapp5 {
    height: 30vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
  .programP_main_wrapp {
    height: 30vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
  .program-title {
    min-height: unset;
    height: auto;
    padding: 12px 8px 12px 20px;
    width: 75%;
    justify-content: flex-start;
}

  .program-title > p {
    font-size: 20px;
  }
  .programP-title {
    min-height: unset;
    height: auto;
    padding: 12px 8px 12px 20px;
    width: 85%;
    justify-content: flex-start;
}

  .programP-title > p {
    font-size: 19px;
  }
}

@media (min-width: 401px) and (max-width: 480px) {
  .program2_main_wrapp {
    height: 30vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
  .program2_main_wrapp2 {
    height: 30vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
  .program2_main_wrapp3 {
    height: 30vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
  .program2_main_wrapp4 {
    height: 30vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
  .program2_main_wrapp5 {
    height: 30vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
  .programP_main_wrapp {
    height: 30vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
  .program-title {
    min-height: unset;
    height: auto;
    padding: 12px 8px 12px 20px;
    width: 75%;
    justify-content: flex-start;
  }

  .program-title > p {
    font-size: 25px;
  }
  .programP-title {
    min-height: unset;
    height: auto;
    padding: 12px 8px 12px 20px;
    width: 85%;
    justify-content: flex-start;
  }

  .programP-title > p {
    font-size: 21px;
  }
}
@media (min-width: 481px) and (max-width: 768px)  {

  .program2_main_wrapp {
    height: 52vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
  .program2_main_wrapp2 {
    height: 52vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
  .program2_main_wrapp3 {
    height: 52vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
  .program2_main_wrapp4 {
    height: 52vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
  .program2_main_wrapp5 {
    height: 52vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
  .programP_main_wrapp5 {
    height: 52vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
  .program-title {
    min-height: unset;
    height: auto;
    padding: 12px 0;
    width: 70%;
    padding: 12px 1rem;

  }
  .program-title > p {
    font-size: 27px;
  }
  .programP-title {
    min-height: unset;
    height: auto;
    padding: 12px 0;
    width: 70%;
    padding: 12px 1rem;

  }
  .programP-title > p {
    font-size: 27px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .program2_main_wrapp {
    height: 70vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
  .program2_main_wrapp2 {
    height: 70vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
  .program2_main_wrapp3 {
    height: 70vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
  .program2_main_wrapp4 {
    height: 70vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
  .program2_main_wrapp5 {
    height: 70vh;
    min-height: 200px;
    padding-bottom: 8%;

  }
    
  .programP-title {
    padding-left: 10%;
}
  .programP-title > p {

    font-size: 30px;
}
}