.lb_card {
  flex-shrink: 0;
  width: 250px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  margin: 1rem;
  height: 420px;
}

.lb_card img {
  width: 100%;
  aspect-ratio: 1/1;
  height: 220px;
}

.lb_card_content {
  padding: 10px;
}

.lb_card_content h4 {
  font-family: var(--font-base);
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px;
}

.lb_card_content p {
  font-family: var(--font-base);
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #919191;
}


