.h-webinar-div {
    width: 100%;
    margin: auto;
    padding: 80px 50px 0px 50px;
}
.h-secc3-title2-web{
    color: #02245B;
    text-align: center;
    font-family: var(--font-rubik);
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 50.775px;
    padding-bottom: 30px;
    /* max-width: 785px; */
    margin: auto;
}
.home-web-content{

}
.home-web-content>h3{
    color: #000000;
    text-align: left;
    font-family: var(--font-base);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 17.609px;
    margin-bottom: 35px;

}
.home-web-content>h4{
    font-size: 14px;
    color: #7D756F;
    font-family: var(--font-base);
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 2rem;

}
.webinar-list {
    font-size: 14px;
    color: #7D756F;
    font-family: var(--font-base);
    font-weight: 600;
    line-height: 24px;
    padding-bottom: 20px;
    display: flex;
    align-items: start;
    gap: 16px;
    text-align: justify;
}
.home-web-btn>button{
    width: auto;
    height: 45px;
    background-color: #02245B;
    color: white;
    font-size: 18px;
    font-family: var(--font-base);
    font-weight: 600;
    line-height: 24.51px;
    padding: 0rem 2rem;
    border-radius: 5px;
}
.h-webinar-img{
    /* text-align: center;
    max-width: 500px;
    margin: 0 auto 2rem; */
    text-align: center;
    max-width: 500px;
    margin: 0 auto 2rem;
    height: 567px;
}
.h-webinar-img>img{
    /* width: 100%; */
    width: 100%;
    height: 100%;
    object-fit: cover;

}


@media screen and (max-width: 768px) {
    .h-secc3-title2-web {
        font-size: 25px;
        line-height: 34.202px;
    }
    .h-webinar-div {
        /* margin-top: 31%; */
        width: 90%;
        padding: 60px 0px 0px 0px;
    }
    .home-web-content>h3 {
        font-size: 20px;
    }
    .home-web-btn>button {
        font-size: 16px;
    }
    .home-web-btn{
        text-align: center;
    }
    .h-webinar-img {

        height: 412px;
    }
}