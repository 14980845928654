.tax_main_wrapp{
    max-width: 1280px;
    margin: auto;
    margin-top: 4rem;
}
.tax_first_blue_wrapp{
    width: 100%;
    max-width: var(--max-width-md);
    background-color: #edeff1;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3rem auto;
    border-radius: 22px;
    min-height: 250px;
    margin-bottom: 40px;
}
.tax_first_blue_wrapp > h1 {
    color: #02245b;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    font-family: var(--font-rubik);
    line-height: 54.305px;
    width: 90%;
    
}

.tax_base_wrapp {
    /* width: 90%; */
    margin: auto;
    max-width: var(--max-width-md);
    padding: 5px 4rem;
}
.green-text-new{
    color: #1EC199;
}
.tax_first_list{
    margin-top: 15px;
}
.tax_des_title {
    color: #002147;
    font-family: var(--font-rubik);
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 53.01px;
}
.tax_first_list>ul>li{
    font-size: 14px;
    color: #444444;
    font-family: var(--font-base);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    
}
.tax_first_list>ul>li>p{
    font-size: 14px;
    color: #444444;
    font-family: var(--font-base);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    margin: 20px 0;
    margin-left: 29px;
}
.tax-description{
    color: #444444;
    font-family: var(--font-base);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: justify;
}
.what-you-pay-list{
    margin-top: 10px;
}
.what-you-pay-list>ul>li{
    display: flex;
    flex-direction: row;
    gap:10px;
    margin-top: 18px;
}
.tax-list-head{
    color: #444444;
    font-family: var(--font-base);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 33px;
    margin: 18px 0;
}
.what-you-pay-list>ul>li>p{
    color: #444444;
    font-family: var(--font-base);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tax_img2{
    height: 100%;
    width: 100%;
    margin: 3rem 0 39px 0;
}
.list-icon{
    width: 20.67px;
    height: 20.67px;

}


@media screen and (max-width: 768px){
    .tax_first_blue_wrapp{
        width: 90%;
        max-width: var(--max-width-md);
        background-color: #edeff1;
        padding: 2rem 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 2rem auto;
        border-radius: 22px;
        min-height: 250px;
        margin-bottom: 40px;
    }
    .tax_base_wrapp {
   
        width: 90%;
        margin: auto;
        max-width: var(--max-width-md);
        padding: 5px 0;
    }
    .tax_des_title {
        font-size: 25px;
        line-height: 30.805px;
        /* text-align: center; */
    }
    .tax_first_blue_wrapp > h1 {
        font-size: 28px;
        line-height: 41.35px;
    }
    .tax-description{
        text-align: justify;
    }
}
