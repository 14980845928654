.home-section3{
    max-width: 1280px;
    margin: auto;
}
.h-sec3-div{
    width: 100%;
    margin: auto;
    padding: 80px 50px 40px 50px;
}
.h-secc3-title1{
    color: #FF5E14;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21.156px; /* 132.227% */
    text-transform: uppercase;
    font-family: var(--font-base);
}
.h-secc3-title2{
    color: #02245B;
    text-align: center;
    font-family: var(--font-rubik);
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 50.775px;
    padding-bottom: 30px;
}
.h-secc3-slider{
  margin-top: 29px;
}
.department{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: 358px;
}
.h-sec3-slider{
  display: flex;

  flex-direction: column;
}
.h-sec3-slider >button{
  width: 4%;
  display: flex;
    justify-content: center;
    align-items: center;
}
.dept-col1{
    display: flex;
    flex-direction: column;
    width: 25%;
    box-sizing: border-box; /* Include padding and borders in the width calculation */
    padding: 10px; 
    /* height: 45vh; */
    /* height: 333px; */
    height: 353px;
}
.dept-col2{
    display: flex;
    /* flex-direction: column; */
    width: 25%;
    box-sizing: border-box; /* Include padding and borders in the width calculation */
    padding: 10px; 
    /* height: 50vh; */
    /* height: 333px; */
    height: 353px;
}
  .dept-card{
    position: relative;
    /* height: 22vh; */
    /* height: 152px; */
    height: 162px;
    width: 100%;
    /* padding: 10px; */
    margin-bottom: 10px;
    transition: 0.3s ease-out;
  }
  .dept-card:hover{
    overflow: hidden;
    box-shadow: 0px 0px 10px 5px rgba(173, 173, 173, 0.18);
    cursor: pointer;
  }
  .dept-card2{
    position: relative;
    /* height: 45vh; */
    height: 100%;
    width: 100%;
    overflow: hidden;
    /* height: 100%; */
    /* padding: 10px; */
    transition: 0.3s ease-out;
  }
  .dept-card2:hover{
    box-shadow: 0px 0px 10px 5px rgba(173, 173, 173, 0.18);
    cursor: pointer;
  }
  .overlay1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(2, 36, 91, 0.00) 0.27%, rgba(0, 0, 0, 0.70) 105.51%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;
 
  }
  .dept-card2:hover .overlay {
    opacity: 0; 
  }  
  .dept-card1:hover .overlay {
    opacity: 0; 
  }  


  .dept-col1 img {
    width: 100%; 
    height: 100%;
    transition: .5s;
    /* object-fit: cover; */
  }
  .dept-col2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .dept-title{
    position: absolute;
    left: 0;
    bottom: 0;
    color: #FFF;
    font-family:var(--font-base);
    /* font-style: normal; */
    /* font-weight: 700; */
    /* line-height: 125.023%; */
    /* text-align: center; */
    width: 100%;
    height: 35px;
    box-sizing: border-box;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0.48, -0.28, 0.41, 1.4);
    z-index: 2;
  }
  .dept-title1{
    text-align: center;
    transition: .5s;
  }
  .dept-des{
    width: 75%;
    margin: auto;
    text-align: left;
  }
  .dept-title p:first-child{
    color: #FFF;
    font-family:var(--font-base);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 111%;
    transition: .5s;
    /* text-shadow: 2px 2px 5px black; */
  }

  .dept-card2::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(2, 36, 91, 0.00) 0.27%, rgba(0, 0, 0, 0.70) 105.51%);
    box-shadow: 0px 0px 10px 5px rgba(173, 173, 173, 0.18);
    z-index: 2;
}
 
.dept-card::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(2, 36, 91, 0.00) 0.27%, rgba(0, 0, 0, 0.70) 105.51%);
  box-shadow: 0px 0px 10px 5px rgba(173, 173, 173, 0.18);
  z-index: 2;
  /* opacity: 0.69; */
  /* transition: 0.5s; */
}
  /* card hovering */
  .dept-title:hover p:first-child{
    color: #FFF;
    font-family:var(--font-base);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 72%;
    /* text-shadow: 2px 2px 5px black; */
  }
  .dept-card2:hover .dept-title{
    width: 100%;
    height: 47%;
    bottom: 0px;
    transition: .5s;
  }
  .dept-card2:hover .dept-title::before{
    top: -55px;
  }
  .dept-card:hover .dept-title .dept-des {
    /* margin: 20px 0 0; */
    padding: 0px 2px;
}
.dept-card:hover .dept-title p:first-child {
    margin: 0px 0 0;
}

.dept-card2:hover .dept-title .dept-des {
    /* margin: 10px 0 0; */
    padding: 0;

}
.dept-card2:hover .dept-title p:first-child {
    margin: 10px 0 0;
}
.dept-card2:hover .dept-des{
  width: 75%;
  margin: auto;
  text-align: left;
}
.dept-card:hover .dept-des{
  width: 75%;
  margin: auto;
  text-align: left;
}



.dept-des{
    color: #FFF;

/* text-align: center; */
font-family: var(--font-base);
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 14px; 
transition: .5s;
}

  .dept-card:hover .dept-title{
    width: 100%;
    height: 67%;
    transition: .5s;
  }
  .dept-title .dept-des{
    /* margin: 0px 0 0; */
    /* padding: 1rem 1rem; */
    padding-top: 1rem;
    /* transform: translateY(1rem); */
    transform: translateY(9px);
    transition: .5s;
  }
  .dept-card:hover .dept-title::before{
    top: -55px;
  }

  .star-list {
    list-style: none;
    padding: 0;
}

.star-list li {
    margin-bottom: 5px; /* Adjust as needed */
    padding-left: 25px; /* Adjust as needed */
    position: relative;
}

.star-list li::before {
    content: "\2022"; /* Unicode for a star symbol */
    font-size: 21px; /* Size of the star */
    margin-right: 5px; /* Spacing between star and text */
    position: absolute;
    left: 0;
    top: 0; /* Align the star icon with the top of the list item */
}


  .dept-card:hover::before{
    width: 100%;
    height: 111%;
    background: rgba(255, 94, 20, 0.61);
    transform: skewY(-12deg);
    bottom: -41px;
    opacity: 18.69;
    transition: 0.5s;
    content: " ";
    position: absolute;
    /* top: 0; */
    top: unset;
    left: 0;
    display: block;

}

  .dept-card2:hover::before{
    width: 100%;
    height: 64%;
    background: rgba(255, 94, 20, 0.61);
    transform: skewY(-12deg);
    bottom: -38px;
    opacity: 18.69;
    transition: 0.5s;
    content: " ";
    position: absolute;
    /* top: 0; */
    top: unset;
    left: 0;
    display: block;

}
.dept-card2::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;

}


.dept-card::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  transform-origin: bottom;
  pointer-events: none; 
}

  .slider-btn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }
  .slider-btn button{
    border: none;
    background: transparent;
    cursor: pointer;
  }
  .department .slick-dots li.slick-active button:before {
    color: #FF5E14 !important;
    font-size: 15px !important;
    /* margin-top: 10px; */
   }
  .department .slick-dots{
    bottom: -43px !important;
    z-index: 1;
    width: 50%;
    margin: auto;
    left: 0;
    right: 0;
   }

  .department .slick-dots li button:hover:before, .slick-dots li button:focus:before {
    opacity: 1;
    color: #FF5E14 !important;
  } 

   .dept-card23{
    position: relative;
    display: block;
    height: 100%;
   }
   .img-fluid {
    max-width: 100%;
    height: auto;
}
.dept-card23:hover .dept-title{
  position: absolute;
  padding: 0 15px;
  width: 100%;
  height: 80px;
  bottom: -110px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(255, 94, 20, 0.51);
  transition: .5s;
  overflow: visible;
}
.dept-card23:hover .dept-title::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 51px;
  border-left: 230px solid transparent;
  border-top: 16px solid transparent;
  border-right: 47px solid rgba(255, 94, 20, 0.51);
  border-bottom: 47px solid rgba(255, 94, 20, 0.51);
  bottom: 80px;
  /* right: 0; */
  left: 0px;
  transform: rotate(360deg);
  transition: .5s;
}
.dept-card23:hover .dept-title {
  bottom: 2px;
}
.department .slick-dots li {
  margin: 0 2px;
}

   @media screen and (max-width: 768px) {
      .h-sec3-div{
        /* margin-top: 31%; */
        width: 90%;
        padding: 60px 0px 24px 0px;
      }
      .dept-card:hover .dept-title p:first-child {
        /* margin: 10px 0 0; */
        /* padding: 0; */
        font-size: 16px;
    }
    .dept-card2:hover .dept-title {
      /* bottom: -63px; */
      height: 43%;
    }
    .dept-title p:first-child {
      font-size: 16px; 
    }
    .dept-card2:hover .dept-title p:first-child {
      font-size: 16px; 
    }
    .dept-des{
      font-size: 11px;
      line-height: 13px;
      width: 65%;
    }
    .h-secc3-title2{
      font-size: 25px;
    }
    .dept-card:hover::before {
      bottom: -39px;
    }
    .dept-card:hover .dept-des {
      width: 65%;
      margin: auto;
      text-align: left;
    }
    .dept-card2:hover .dept-des {
      width: 65%;
      margin: auto;
      text-align: left;
    }
    .dept-card:hover::before {
      height: 106%;
    }
    .department {
      /* padding: 0 1rem; */
      width: 100%;
      height: auto;
    }
    .h-sec3-slider{
      height: auto;
    }
    .h-sec3-slider >button {
      width: 10%;
    }
    .h-sec3-slider {
      margin: auto;
      width: 95%;
    }
    .department .slick-dots {
      width: 76%;
  }
   }



   /* tablet view  */
@media (min-width: 768px) and (max-width: 1116px){ 
  .dept-title p:first-child {
    font-size: 17px; 
  }
  .dept-des {
    font-size: 13px;
    line-height: 15px;
}
.dept-card:hover .dept-title p:first-child {
  /* padding: 0; */
  font-size: 17px;
  }
  .dept-card2:hover .dept-title p:first-child {
    font-size: 17px; 
  }
  .star-list {
    line-height: 12px;
  }
} 

@media (min-width: 1117px) and (max-width: 1245px){ 
  .dept-title p:first-child {
    font-size: 17px;
  }
  .dept-des{
    font-size: 12px;
  }
  .dept-title:hover p:first-child {
    font-size: 17px;
  }

}
@media (min-width: 481px) and (max-width: 545px){
  .dept-title p:first-child {
    font-size: 15px;
  }
  .dept-title:hover p:first-child {
    font-size: 15px;
  }
  .dept-card2:hover .dept-des {
    width: 92%;
  }
  .dept-card:hover .dept-des {
    width: 92%;
  }
  .dept-des {
    width: 92%;
  }
  .dept-col1 {
    height: 393px;
  }
  .dept-card {
    height: 161px;
  }
 }
 @media (max-width: 481px) {
  .dept-col1 {
    height: 393px;
  }
  .dept-card {
    height: 182px;
  }
  .dept-col2 {
    height: 393px;
  }
  .h-sec3-slider {
    margin: auto;
    width: 95%;
  }
 }