.pro_nav_main_wrapp {
  display: flex;
  align-items: center;
  background: var(--color-white);
  width: 100%;
  /* min-width: 100vw; */
  gap: 15%;
  position: sticky;
  /* top: 84px; */
  top: 99.8px;
  left: 0px;
  margin-top: 3%;
  z-index: 1
  /* border: 1px solid black; */
}
.pro_nav_base_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #e7e8fb;
  background: #fff;
  overflow-x: scroll;
}
.pro_nav_base_wrapp::-webkit-scrollbar {
  display: none; /* Hide the scrollbar on webkit-based browsers */
}

/* internship navbar */
.pro_nav_links {
  /* display: -webkit-inline-box;
  display: flex;
  justify-content: space-between; */

  align-items: center;
  flex-wrap: wrap;
  row-gap: 20px;
  width: 100%;
  column-gap: 11px;

  display: grid;
  grid-template-columns: repeat(8, 1fr);
}
.pro_nav_links > button {
  color: #231f40;
  text-align: center;
  font-family: var(--font-urbanist);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* text-transform: capitalize; */
  display: block;
  padding: 0.5rem 1rem;
  width: max-content;

}
.pro_nav_links button.selected-link {
  background: #ff8000;
  color: white;
  border-radius: 5px;
  /* height: 40px; */
  padding: 0.5rem 1rem;
  /* width: auto; */
  
  /* width: 190px; */
}

/* training navbar */
.pro_nav_links2 {
  /* display: -webkit-inline-box; */
  display: flex;
  justify-content: space-between;

  align-items: center;
  flex-wrap: wrap;
  row-gap: 20px;
  width: 100%;
  column-gap: 5px;

  /* display: grid;
  grid-template-columns: repeat(7, 1fr); */
}
.pro_nav_links2 > button {
  color: #231f40;
  text-align: center;
  font-family: var(--font-urbanist);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* text-transform: capitalize; */
  display: block;
  padding: 0.5rem 1rem;
  width: max-content;

}
.pro_nav_links2 button.selected-link {
  background: #ff8000;
  color: white;
  border-radius: 5px;
  /* height: 40px; */
  padding: 0.5rem 1rem;
  /* width: auto; */
  

}

/* step navbar */
.pro_nav_links_step{
  display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 20px;
    width: 100%;
    column-gap:9px;
}
.pro_nav_links_step > button {
  color: #231f40;
  text-align: center;
  font-family: var(--font-urbanist);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* text-transform: capitalize; */
  display: block;
  padding: 0.5rem 1rem;
}
.pro_nav_links_step button.selected-link {
  background: #ff8000;
  color: white;
  border-radius: 5px;
  /* height: 40px; */
  padding: 0.5rem 1rem;
  /* width: auto; */
  
  /* width: 190px; */
}
.pro_nav_links_au{
  display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 20px;
    width: 100%;
    column-gap: 26px;
}
.pro_nav_links_au > button {
  color: #231f40;
  text-align: center;
  font-family: var(--font-urbanist);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-transform: capitalize;
  display: block;
  padding: 0.5rem 1rem;
}
.pro_nav_links_au button.selected-link {
  background: #ff8000;
  color: white;
  border-radius: 5px;
  /* height: 40px; */
  padding: 0.5rem 1rem;
  /* width: auto; */
  
  /* width: 190px; */
}

/* new pro navbar */
.pro_nav_links_pannonia{
  display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 10px;
    width: 100%;
    column-gap:8px;
}
.pro_nav_links_pannonia > button {
  color: #231f40;
  text-align: center;
  font-family: var(--font-urbanist);
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* text-transform: capitalize; */
  display: block;
  padding: 0.5rem 1rem;
}
.pro_nav_links_pannonia button.selected-link {
  background: #ff8000;
  color: white;
  border-radius: 5px;
  /* height: 40px; */
  padding: 0.5rem 1rem;
  /* width: auto; */
  
  /* width: 190px; */
}
@media screen and (max-width: 768px) {
  .pro_nav_links > button {
    font-size: 14px;
    line-height: 18.903px;
    width: max-content;
  }
  .pro_nav_links {
    display: flex;
    align-items: center;
    justify-content:unset;
    flex-wrap: wrap;
    /* column-gap: 42px; */
    /* column-gap: 17px; */
    row-gap:15px;
    width: 100%;
  }
  .pro_nav_links2 > button {
    font-size: 14px;
    line-height: 18.903px;
    width: max-content;
  }
  .pro_nav_links2 {
    display: flex;
    align-items: center;
    justify-content:unset;
    flex-wrap: wrap;
    /* column-gap: 42px; */
    /* column-gap: 15px; */
    row-gap: 15px;
    width: 100%;
  }
  .pro_nav_links_step > button {
    font-size: 14px;
    line-height: 18.903px;
    width: auto;
    text-align: left;

  }
  .pro_nav_links_pannonia {
    display: flex;
    align-items: center;
    justify-content:unset;
    flex-wrap: wrap;
    /* column-gap: 42px; */
 
    column-gap: 0px;
    row-gap: 15px;
    width: 100%;

  }
  .pro_nav_links_pannonia > button {
    font-size: 14px;
    line-height: 18.903px;
    width: auto;
    text-align: left;

  }
  .pro_nav_links_pannonia {
    display: flex;
    align-items: center;
    justify-content:unset;
    flex-wrap: wrap;
    /* column-gap: 42px; */
 
    column-gap: 0px;
    row-gap: 7px;
    width: 100%;

  }
  .pro_nav_links_au > button {
    font-size: 14px;
    line-height: 18.903px;
    width: max-content;
  }
  .pro_nav_links_au {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /* column-gap: 42px; */
    /* column-gap: 17px; */
    row-gap: 15px;
    justify-content:unset;
    column-gap: 26px;
    width: 100%;
  }
  .pro_nav_main_wrapp {

    top: 94.8px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .pro_nav_links > button {
    font-size: 16px;
    line-height: 18.903px;
    width: max-content;
  }
  .pro_nav_links {
    display: flex;
    align-items: center;
    justify-content:unset;
  }
  .pro_nav_links2 > button {
    font-size: 16px;
    line-height: 18.903px;
    width: max-content;
  }
  .pro_nav_links2 {
    display: flex;
    align-items: center;
    justify-content:unset;
  }
  .pro_nav_links_step > button {
    font-size: 16px;
    line-height: 18.903px;
    width: max-content;
  }
  .pro_nav_links_step {
    display: flex;
    align-items: center;
    justify-content:unset;
  }
  .pro_nav_links_pannonia > button {
    font-size: 16px;
    line-height: 18.903px;
    width: max-content;
  }
  .pro_nav_links_pannonia {
    display: flex;
    align-items: center;
    justify-content:unset;
  }
  .pro_nav_links_au > button {
    font-size: 16px;
    line-height: 18.903px;
    width: max-content;
  }
  .pro_nav_links_au {
    display: flex;
    align-items: center;
    justify-content:unset;
  }
}
@media (min-width: 1024px) and (max-width: 1181px) {
  .pro_nav_links > button {
    font-size: 16px;
    line-height: 18.903px;
    width: max-content;
  }
  .pro_nav_links2 > button {
    font-size: 16px;
    line-height: 18.903px;
    width: max-content;
  }
  .pro_nav_links_step > button {
    font-size: 16px;
    line-height: 18.903px;
    width: max-content;
  }
  .pro_nav_links_au > button {
    font-size: 16px;
    line-height: 18.903px;
    width: max-content;
  }
  .pro_nav_links2 {
    justify-content: unset;
}
}
@media (min-width: 1181px) and (max-width: 1230px) {
  .pro_nav_links2 {
    justify-content: unset;
}
}

