.footer_main_wrapp {
  background-color: #fbfbfb;
}

.footer_first_wrapp {
  width: 100%;
  height: 195px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex: 1;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.footer_first_wrapp_sec1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 15%;
  width: 23%;
  height: 100%;
}

.footer_first_wrapp_sec1 > img {
  height: auto;
  object-fit: contain;
  /* max-width: 164px; */
  max-width: 100px;
}

.footer_first_wrapp_sec2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  flex: 0.2;
  height: 100%;
}

.footer_first_wrapp_sec2 > a {
  font-family: var(--font-base);
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
  color: #090914;
}

.footer_first_wrapp_sec4_part2 > a > p {
  font-size: 13.61px;
  font-weight: 600;
}

.footer_first_wrapp_sec3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  height: 100%;
}

.footer_first_wrapp_sec3 > a {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.footer_first_wrapp_sec3 > a > p {
  font-family: var(--font-base);
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
  color: #090914;
}

.footer_first_wrapp_sec5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 23%;
  height: 100%;
}

.footer_first_wrapp_sec5 > img {
  max-width: 180px;
  max-height: 90px;
  object-fit: contain;
}

.footer_first_wrapp_sec4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
  flex: 0.2;
  height: 100%;
}

.footer_first_wrapp_sec4 > div {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
}

.footer_first_wrapp_sec4_part2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4px;
}

.footer_first_wrapp_sec4_part2 > label {
  font-family: var(--font-base);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
  color: #090914;
}

.footer_first_wrapp_sec4_part2 > p {
  font-family: var(--font-base);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  color: #090914;
}

.footer_secound_wrapp {
  width: 100%;
  background-color: #bdbdbd;
  height: 1px;
  margin-bottom: 1rem;
}

.footer_third_wrapp {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.footer_third_wrapp >div > p {
  font-family: var(--font-base);
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0;
  color: var(--color-black);
}

.msicon_div {
  margin-top: 2px;
}

.terms_privacy {
  color: var(--color-sky-blue) !important;
  text-align: center;
}
.design-by{
  text-align: end;
}
.footer_third_wrapp >div{
  width: 33.33%;
}

@media (max-width: 920px) {
  .footer_first_wrapp_sec1 {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    min-width: 100%;
  }

  .footer_first_wrapp {
    flex-direction: column;
    height: 100%;
    gap: 1rem;
  }

  .footer_first_wrapp_sec2,
  .footer_first_wrapp_sec3,
  .footer_first_wrapp_sec4 {
    min-width: 200px;
  }

  .footer_third_wrapp {
    flex-direction: column;
  }
  .footer_third_wrapp >div{
    width: 100%;
  }
  .footer_third_wrapp >div >p{
    text-align: center
  };
  .design-by{
    text-align: center;
  }
}

@media (max-width: 768px) {
  .footer_first_wrapp {
    flex-direction: column;
    height: 100%;
    gap: 2rem;
  }

  .footer_first_wrapp_sec2,
  .footer_first_wrapp_sec3,
  .footer_first_wrapp_sec4,
  .footer_first_wrapp_sec5 {
    min-width: 250px;
    align-items: center;
    min-height: auto;
  }

  .footer_third_wrapp {
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer_first_wrapp_sec4 > div {
    width: 204px;
  }

  .footer_first_wrapp_sec3 > a {
    width: 94px;
  }

  .footer_first_wrapp_sec3 {
    flex-direction: column;
    gap: 1rem;
  }

  .footer_secound_wrapp {
    margin-top: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .footer_first_wrapp_sec5 {
    width: 28%;
}
}