.aboutweare_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  /* min-height: 100vh; */
}

.aboutweare_img_wrapp_new {
  width: 45%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}

.aboutweare_img_wrapp {
  width: 45%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.about_whatwe_img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.about_whatwe_img1 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.about_whatwe_img1_new {
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
  position: absolute;
    left: 10%;
  
}

.aboutwhatwe_textwrapp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 48%;
  height: 100%;
  padding: 1vh 10px;
}

.aboutwhatwe_orange_text {
  font-family: var(--font-base);
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-orange);
  margin-bottom: 10px;
  text-transform: uppercase;
}

.aboutwhatwe_blue_text {
  font-family: var(--font-rubik);
  font-size: 36px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-blue);
  margin-bottom: 20px;
  width: 86%;
}

.aboutweare_inner_wrapp {
  display: flex;
  width: 100%;
  height: 80vh;
  align-items: center;
  justify-content: flex-start;
  /* gap: 2%; */
  gap: 4%;
}

.aboutwhatwe_blueline_text_wrapp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

.aboutwhatwe_blueline {
  width: 4px;
  background-color: var(--color-sky-blue);
  height: 100%;
  border-radius: 4px;
}

.aboutwhatwe_blueline_text {
  color: var(--color-black);
  font-size: 14px;
  line-height: 29px;
  font-family: var(--font-base);
  font-weight: 600;
  width: 96%;
  text-align: justify;
}
.aboutwhatwe_blueline_text_blw {
  color: var(--color-black);
  font-size: 14px;
  line-height: 29px;
  font-family: var(--font-base);
  font-weight: 600;
  width: 96%;
  text-align: justify;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.about_whatwe_rotate_logo {
  position: absolute;
  left: -4%;
  top: -1%;
  width: 150px;
  height: 150px;
  animation: rotate360 10s linear infinite; /* Adjust the duration and timing function as needed */
}

@media (max-width: 768px) {
  .aboutweare_inner_wrapp {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin: 1rem auto; */
    height: 100%;
    gap: 0rem;
    /* padding-right: 13px; */
  }

  .aboutweare_img_wrapp {
    width: 100%;
    height: 311px;
    /* margin: 1rem auto; */
  }

  .aboutwhatwe_textwrapp {
    width: 100%;
    align-items: center;
    margin: 1rem auto;
    padding: 1vh 0px;
  }

  .aboutwhatwe_blueline_text_wrapp {
    gap: 0px;
    justify-content: center;
  }

  .aboutwhatwe_orange_text {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }

  .aboutwhatwe_blue_text {
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    width: 100%;

  }

  .aboutwhatwe_blueline_text {
    text-align: justify;
    line-height: 22px;
  }
  .aboutwhatwe_blueline_text_blw{
    text-align: center;
    line-height: 22px;
  }
  .about_whatwe_img1 {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-left: 25px;
    
  }

  .aboutweare_img_wrapp_new {
    width: max-content;
    height: 311px;
    margin-bottom: 9px;
  }

  .about_whatwe_img1_new {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-left: 25px;
    position: unset;
  }

  .about_whatwe_rotate_logo {
    position: absolute;
    left: -3%;
    top: -1%;
    width: 95px;
    height: 95px;
  }
  .aboutwhatwe_blueline {
    width:unset;
 
  }
}

@media (min-width: 769px) and (max-width:1024px){
  .aboutwhatwe_blue_text {
    width: 100%;
}
  }
