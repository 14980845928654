.bd_img_wrapp {
  /* width: 90vw; */
  width: 82vw;
  margin:0 auto 10px auto;
  height: 530px;
  min-height: 491px;
  position: relative;
}

.bd_img_wrapp > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  object-position: top;
}
.blog_detail_large_img{
  width: 100%;
  height: 100%;
}
.blog_detail_mob_img{
  display: none;
}
.blog_detail_mob_img_au{
  display: none;
}
.bd_first_blue_wrapp {
  width: 100%;
  max-width: var(--max-width-md);
  background-color: #edeff1;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem auto;
  border-radius: 22px;
  min-height: 250px;
}

.bd_first_blue_wrapp > span {
  font-family: var(--font-base);
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-orange);
}

.bd_first_blue_wrapp > h1 {
  color: #02245b;
  text-align: center;
  font-family: var(--font-rubik);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 54.305px;

  width: 90%;
}
.blog-green-text {
  color: #06c25e;
}
.aboutwhatwe_blue_text1 {
  color: #02245b;
  font-family:var(--font-rubik);;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 50.476px; /* 148.459% */
}
.simpleandbeautiful_blue_text {
  color: #02245b;
  font-family: var(--font-base);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 40.381px; /* 144.218% */
}
.bd_first_blue_wrapp > p {
  font-family: var(--font-base);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: #161616;
  margin-top: 2rem;
}

/* details wrapp start*/

.bd_details_main_wrapp {
  width: 100%;
  max-width: var(--max-width-md);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem auto;
  border-radius: 22px;
}

.bd_details_first_paregraph {
  font-family: var(--font-base);
  font-size: 14px;
  font-weight: 400;
  /* line-height: 35px; */
  /* letter-spacing: 0em; */
  text-align: left;
  color: #151515;
  line-height: 24.9px;
  text-align: justify;
}

.bd_details_first_paregraph::first-letter {
  float: left;
  font-size: 46px;
  line-height: 38px;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 3px;
  color: var(--color-orange);
}

.bd_details_secound_paregraph {
  font-family: var(--font-base);
  font-size: 14px;
  font-weight: 400;
  /* letter-spacing: 0em; */
  text-align: left;
  color: #151515;
  width: 100%;
  line-height: 24.9px;
  text-align: justify;
}

.bd_details_img {
  width: 100%;
  aspect-ratio: 4 / 3;
  /* max-height: 400px; */
  margin: 2rem auto;
}

.bd_blueline_text_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin: 2rem auto;
  width: 100%;

  /* min-height: 300px; */
}

.bd_blueline {
  width: 4px;
  background-color: #06c25e;
  height: 100%;
  border-radius: 4px;
  min-height: 200px;
}

.bd_blueline_text {
  font-family: var(--font-base);
  font-size: 30px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  color: #151515;
  width: 90%;
}
/* details wrapp end */

/* secound_blue box start */

.bd_secound_blue_box_wrapp {
  width: 100%;
  padding: 2rem;
  background-color: #edeff1;
  border-radius: 8px;
  max-width: 875px;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 294px;
}

.bd_secound_blue_first_part {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 26%;
}

.bd_secound_blue_first_part > img {
  width: 100px;
  height: 69px;
  /* border-radius: 100px; */
}

.bd_secound_blue_first_part > h3 {
  font-family: var(--font-base);
  font-size:  19px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-blue);
}

.bd_secound_blue_first_part > a {
  font-family: var(--font-base);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  color: var(--color-orange);
}

.bd_secound_blue_secound_part {
  width: 2px;
  height: 100%;
  background-color: #e6e6e6;
  min-height: 150px;
  border-radius: 2px;
  margin: auto 10px;
}

.bd_secound_blue_third_part {
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.bd_secound_blue_third_part p {
  font-family: var(--font-base);
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #151515;
}

.bd_secound_blue_third_part > div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 14px;
}

.bd_secound_blue_third_part > div > a {
  background-color: #fff;
  border-radius: 4px;
  border: 2px solid #e6e6e6;
  padding: 6px 12px;
  color: #151515;
  font-family: var(--font-base);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12.497px;
}
/* secound_blue box end */

/* recent blog section start */

.recent_blog_main_wrapp {
  padding: 10px;
  margin: 1rem auto;
  margin-bottom: 5rem;
}

.recent_blog_main_wrapp > h2 {
  color: #02245b;
  font-family: var(--font-rubik);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 71.5px; /* 198.611% */
  margin-bottom: 2rem;
  padding-left: 2rem;
}

.recent_blog_card_wrapp {
  width: 100%;
  /* display: flex;
  align-items: center;
  justify-content: space-between; */

  /* container-type: inline-size; */
  display: grid;
  row-gap: 2rem;
  /* grid-template-columns: repeat(auto-fill, minmax(min(408px, 100%), 1fr)); */
  grid-template-columns: repeat(auto-fill, minmax(min(365px, 100%), 2fr));
}

.bd_details_secound_title{
  color: #02245b;
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 50.48px; 
  width: 100%;
  margin-top: 10px;
  /* margin-bottom: 2rem;
  padding-left: 2rem; */
}
.bd_bold_question{
  color: #151515;
  font-family: var(--font-base);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; 
  width: 100%;
  text-align: justify;
}
.pbb1{
  padding-bottom: 1rem;
}
.blog_shape_top{
  width: 150px !important;
  position: absolute;
  top: 0;
  right: -1px;
  height: auto !important;
}

.blog_shape_btm{
  position: absolute;
  bottom: -3px;
  width: 241px !important;
  left: 79px;
  height: auto !important;
}

/* recent blog section end */

/* media query start */

@media (max-width: 480px) {
  .bd_img_wrapp {
    /* height: 300px; */
    height: auto;
    min-height: 200px;
    margin: 10px auto;
    width: 90vw;
  }

  .bd_first_blue_wrapp {
    width: 90%;
  }
  .bd_first_blue_wrapp > span {
    font-size: 18px;
  }
  .bd_first_blue_wrapp > h1 {
    font-size: 28px;
    line-height: 125%;
    width: 100%;
    margin-bottom: 10px;
  }

  .bd_first_blue_wrapp > p {
    font-size: 16px;
    line-height: 125%;
  }

  .bd_details_first_paregraph,
  .bd_details_secound_paregraph {
    /* font-size: 16px; */
    /* line-height: 125%; */
    text-align: justify;
  }

  .bd_details_first_paregraph::first-letter {
    float: left;
    font-size: 43px;
    line-height: 30px;
    padding-top: 4px;
    padding-right: 8px;
    padding-left: 3px;
    color: var(--color-orange);
  }

  .bd_blueline_text {
    font-size: 20px;
    line-height: 125%;
    text-align: justify;
  }

  .bd_secound_blue_box_wrapp {
    width: 90%;
    flex-direction: column;
    height: auto;
    
  }

  .bd_secound_blue_first_part,
  .bd_secound_blue_third_part {
    width: 100%;
  }

  .bd_secound_blue_secound_part {
    width: 100%;
    height: 2px;
    min-height: auto;
    margin: 10px auto;
  }

  .bd_secound_blue_third_part,
  .bd_secound_blue_third_part > div {
    align-items: center;
    justify-content: center;
  }

  .recent_blog_main_wrapp > h2 {
    font-size: 30px;
    text-align: center;
    padding-left: 0rem;
  }
  .aboutwhatwe_blue_text1 {
    font-size: 25px;
    line-height: 37.476px;
  }
  .blog_detail_large_img{
    width: 100%;
    height: 100%;
    display: none;
  }
  .blog_detail_mob_img{
    display:block;
    width: 100%;
    height: 100%;
  }
  .blog_detail_mob_img_au{
    display:block;
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
    border-radius: 12px;
  }
  .bd_secound_blue_third_part > div > a {
    width: 47%;
    min-height: 49px;
    display: flex;
    align-items: center;
  }
  .bd_details_main_wrapp {
    width: 90%;
    padding: 2rem 0;
  }
  .bd_details_secound_title { 
    font-size: 20px;
    line-height: 31px;
    margin-bottom: 10px;
  }
  .recent_blog_main_wrapp {
    padding: 10px 0;
  }
  .blog_shape_top{
display: none;
  }
  
  .blog_shape_btm{
    display: none;
  }
}
@media (min-width: 481px) and (max-width:768px){
  .bd_img_wrapp {
    /* height: 50vh; */
    height: auto;
    min-height: 200px;
    margin: 10px auto;
    width: 90vw;
  }

  .bd_first_blue_wrapp {
    width: 90%;
  }
  .bd_first_blue_wrapp > span {
    font-size: 18px;
  }
  .bd_first_blue_wrapp > h1 {
    font-size: 28px;
    line-height: 125%;
    width: 100%;
    margin-bottom: 10px;
  }

  .bd_first_blue_wrapp > p {
    font-size: 16px;
    line-height: 125%;
  }

  .bd_details_first_paregraph,
  .bd_details_secound_paregraph {
    /* font-size: 16px; */
    /* line-height: 125%; */
    text-align: justify;
  }

  .bd_details_first_paregraph::first-letter {
    float: left;
    font-size: 43px;
    line-height: 30px;
    padding-top: 4px;
    padding-right: 8px;
    padding-left: 3px;
    color: var(--color-orange);
  }

  .bd_blueline_text {
    font-size: 20px;
    line-height: 125%;
    text-align: justify;
  }

  .bd_secound_blue_box_wrapp {
    width: 90%;
    flex-direction: column;
    height: auto;
    
  }

  .bd_secound_blue_first_part,
  .bd_secound_blue_third_part {
    width: 100%;
  }

  .bd_secound_blue_secound_part {
    width: 100%;
    height: 2px;
    min-height: auto;
    margin: 10px auto;
  }

  .bd_secound_blue_third_part,
  .bd_secound_blue_third_part > div {
    align-items: center;
    justify-content: center;
  }

  .recent_blog_main_wrapp > h2 {
    font-size: 30px;
    text-align: center;
    padding-left: 0rem;
  }
  .aboutwhatwe_blue_text1 {
    font-size: 25px;
    line-height: 37.476px;
  }
  .blog_detail_large_img{
    width: 100%;
    height: 100%;
    display: none;
  }
  .blog_detail_mob_img{
    display:block;
    width: 100%;
    height: 100%;
  }
  .blog_detail_mob_img_au{
    display:block;
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
    border-radius: 12px;
  }
  .bd_secound_blue_third_part > div > a {
    width: 47%;
    min-height: 49px;
    display: flex;
    align-items: center;
  }
  .bd_details_main_wrapp {
    width: 90%;
    padding: 2rem 0;
  }
  .bd_details_secound_title { 
    font-size: 20px;
    line-height: 31px;
    margin-bottom: 10px;
  }
  .blog_shape_top{
    display: none;
      }
      
      .blog_shape_btm{
        display: none;
      }
}



@media (min-width: 768px) and (max-width:1024px){
  .bd_secound_blue_box_wrapp {
    width: 93%;
  }
  .bd_img_wrapp {
    /* height: 50vh; */
    height: auto;
    min-height: auto;
    width: 90vw;
}
.bd_first_blue_wrapp {
  width: 90%;
}
}

@media (min-width: 300px) and (max-width:330px){
  .bd_secound_blue_third_part > div > a {
    width: 70%;
    min-height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
}
}

/* media query end */
