.home-about{
    width: 100%;
    margin: auto;
    /* margin-top: 4.5%; */
}
.home-about-wrap{
    max-width: 1280px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    padding-left: 50px;
    padding-right: 50px;
}

.home-about-content,
.home-about-img {
    box-sizing: border-box;
    /* width: 50%; */
}
.home-about-content{
    padding-left: 16px;
    width: 45%;
    z-index: 1;
}
.home-about-img{
    width: 55%;
}
.h-a-title1{
    color: #FF5E14;
    font-family: var(--font-base);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18.001px; /* 112.504% */
    text-transform: uppercase;
    padding-bottom: 9px;
}
.h-a-title2{
    color: #02245B;
    font-family: var(--font-rubik);
    font-size: 36.001px;
    font-style: normal;
    font-weight: 700;
    line-height: 43.202px; /* 120% */
    padding-bottom: 27px;
}
.h-a-des{
    color: #7D756F;
    font-family:var(--font-base);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.001px; /* 150.005% */
    text-align: justify;
}
.home-about-details{
    margin-top: 35px;
    display: flex;
    flex-direction: row;
}
.rectangle{
    display: flex;
width: 123px;
height: 240px;
padding: 53px 18px;
flex-direction: column;
justify-content: center;
align-items: center;
flex-shrink: 0;
background: #FF5E14;
color: white;

}
.rectangle .p1{
    color: #FFF;
font-family: var(--font-rubik);
font-size: 56px;
font-style: normal;
font-weight: 700;
line-height: 64.803px; /* 115.719% */
}
.rectangle .p2{
    color: #FFF;
text-align: center;
font-family: var(--font-rubik);
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 25.001px; /* 100.004% */
}
.home-about-details .description{
    width: 80%;
    padding-left: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.des-p1{
    color: #89807A;
    font-family: var(--font-base);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18.001px; /* 112.504% */
    padding-bottom: 5px;
}
.des-p2{
    color: #7D756F;
font-family: var(--font-base);
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 18.001px; /* 128.577% */
padding-left: 16px;
text-align: justify;
}
.h-a-mission ,.h-a-vision{
    /* width: 106%; */
}
.h-a-social-btn{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 30px;
}
.h-a-email,.h-a-skype{
    width: 50%;
    display: flex;
    align-items: center;
    gap: 11px;
}
.h-a-del{
    display: flex;
    flex-direction: column;
    /* padding-left: 20px; */
}
.h-a-del a{
    text-decoration: none;
    color: #02245B;
font-family: var(--font-base);
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 18.001px; /* 128.577% */
}
.h-a-del p{
    color: #4D4D4D;
font-family: var(--font-base);
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 18.001px; /* 112.504% */
}
.home-about-img img{
    width: 100%;
}
.h-a-social-btn img{
    width: 33px;
    height: 33px;
}

@media screen and (max-width: 768px) {
    .home-about-wrap {
        padding-left: 0px;
        padding-right: 0px;
    }
    .home-about-content,
    .home-about-img {
        max-width: 85%;
        margin: auto;
        width: 100%;
        margin-top: 28px;
        padding-left: 0;
    }
    .h-a-des {
        width: 100%;
        text-align: justify;
    }
    .h-a-mission, .h-a-vision {
        width: 100%;
        text-align: justify;
    }
    .h-a-mission{
        padding-bottom: 16px;
    }
    .rectangle {
        height: auto;
    }
    .h-a-social-btn {

        flex-direction: column;
        width: 100%;
        margin-top: 30px;
        gap: 18px;
    }
    .h-a-title2{
        font-size: 25px;
        padding-bottom: 10px;
    }
}

@media (min-width: 769px) and (max-width:1024px){
    .home-about-wrap {
        padding-left: 0px;
        padding-right: 0px;
    }
    .home-about-content,
    .home-about-img {
        max-width: 90%;
        margin: auto;
        width: 100%;
        margin-top: 28px;
        padding-left: 0;
    }
    .h-a-des {
        width: 100%;
        text-align: justify;
    }
    .h-a-mission, .h-a-vision {
        width: 100%;
        text-align: justify;
    }
    .h-a-mission{
        padding-bottom: 16px;
    }
    .rectangle {
        height: auto;
    }
    .h-a-social-btn {
        margin-top: 30px;
        gap: 18px;
    }
}
@media screen and (max-width: 1200px) {
    .home-about-wrap {
        padding-left: 0px;
        padding-right: 0px;
    }
    .home-about-content,
    .home-about-img {
        max-width: 90%;
        margin: auto;
        width: 100%;
        margin-top: 28px;
        padding-left: 0;
    }
    .h-a-des {
        width: 100%;
        text-align: justify;
    }
    .h-a-mission, .h-a-vision {
        width: 100%;
        text-align: justify;
    }
    .h-a-mission{
        padding-bottom: 16px;
    }
    .rectangle {
        height: auto;
    }
    .h-a-social-btn {
        margin-top: 30px;
        gap: 18px;
    }
}