.home-section2 {
  /* background: rgba(19, 44, 69, 0.92); */
  background-image: url("../../assets/images/home/people-learning.webp");
  height: 90vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  /* margin-top: 58px; */
  /* margin-top: -5px; */
  width: 100%;
}
.sec2-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(19, 44, 69, 0.92);
}
.sec2-bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  pointer-events: none;
}
.sec2-bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.home-sec2-content {
    max-width: 1280px;
    padding-left: 50px;
    padding-right: 50px;
    margin: auto;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    height: 100%;
    margin-top: 35px;
}

.h-sec2-head {
  display: flex;
  gap: 29px;
  margin-top: 26px;
  height: 56px;
}
.h-sec2-head p {
  color: #fff;
  font-family: var(--font-rubik);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 43.202px;
  display: flex;
  /* text-align: center; */
  justify-content: center;
  align-items: center;

}
.h-cul-title{
  text-transform: uppercase;
}
.h-sec2-head div {
  width: 15px;
  /* height: 56px; */
  flex-shrink: 0;
  background: #1ec199;
}

.sec2-cards-div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
  justify-content: center;
  gap: 33px;
  /* Other styling for spacing, etc. */
}

.sec2-card {
  width: calc(18% - 3px);
  /* margin: 16px; */
  /* width: 282px; */
  height: 310px;
  flex-shrink: 0;
  position: relative;
  border-radius: 35px;
  transition: 0.5s;
  cursor: pointer;
  overflow: hidden;
}
.sec2-card img {
  width: 100%;
  height: 100%;
  border-radius: 35px;
  position: relative;
  object-fit: cover;
}
.sec2-card-del {
  position: relative;
  bottom: 87px;
  /* left: 20px; */
  z-index: 3;
  width: 100%;
  transition: 0.5s;
  /* padding: 0 10px 0 10px; */
}
.sec2-card-del::before {
  content: " ";
  display: block;
  width: 16px;
  height: 3px;
  background: #fff;
  position: absolute;
  left: 0;
  top: 15%;
  /* transition: 0.5s; */
}
.sec2-card-del1 {
  position: relative;
  bottom: 87px;
  /* left: 20px; */
  z-index: 3;
  width: 100%;
  transition: 0.5s;
  /* padding: 0 10px 0 10px; */
}
.sec2-card-del1::before {
  content: " ";
  display: block;
  width: 14px;
  height: 3px;
  background: #fff;
  position: absolute;
  left: 0;
  top: 12%;
  /* transition: 0.5s; */
}

.p-title {
  color: #fff;
  font-family: var(--font-base);
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 21.359px;
  text-transform: uppercase;
  position: relative;
  width: 83%;
  margin: auto;
  transition: 0.5s;
}

.p-des {
  color: #fff;
  font-family: var(--font-base);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  width: 81%;
  margin: auto;
}
.sec2-card::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    #3588d7 0%,
    #2487e4 7.16%,
    rgba(11, 134, 248, 0.6) 17.43%,
    rgba(14, 72, 130, 0) 38.46%
  );
  z-index: 2;
  border-radius: 35px;
  /* opacity: 0.69; */
  /* transition:0.5s; */
  transition: all 0.5s cubic-bezier(0.48, -0.28, 0.41, 1.4);
}
.p-title span {
  /* padding-left: 7px; */
}

/* when hovering the card */
.sec2-card:hover .sec2-card-del {
  height: auto; /* Show full content on hover */
  z-index: 10;
  bottom: 80%;
  left: 0;
  text-align: center;
  transform: translateY(0px);
  /* padding: 37px; */
  /* transition: height 0.5s ease; */
}
.sec2-card:hover .sec2-card-del1 {
  height: auto; /* Show full content on hover */
  z-index: 10;
  bottom: 80%;
  left: 0;
  text-align: center;
  transform: translateY(0px);
  /* padding: 37px; */
  /* transition: height 0.5s ease; */
}

.full-details {
  display: none;
  color: #fff;
  font-family: var(--font-base);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.sec2-card:hover .full-details {
  display: block; /* Show full content on hover */
  padding: 0 20px 0 20px;

}
.sec2-card:hover .p-des {
  display: none; /* Ensure it's above other content */
}
.sec2-card:hover .p-title {
  padding-left: 1px;
  padding-right: 1px;
  padding-bottom: 13px;
}
.sec2-card:hover::before {
  background: linear-gradient(
    0deg,
    #3588d7 0%,
    #2487e4 7.16%,
    rgba(11, 134, 248, 0.6) 101.43%,
    rgba(14, 72, 130, 0) 154.46%
  );
  z-index: 2;
  border-radius: 35px;
  opacity: 18.69;
  transition: 0.5s;
}

.sec2-card:hover .sec2-card-del::before {
  content: " ";
  display: block;
  width: 19px;
  height: 3px;
  background: #fff;
  position: absolute;
  left: 0;
  top: 5%;
}
.sec2-card:hover .sec2-card-del::after {
  content: " ";
  display: block;
  width: 19px;
  height: 3px;
  background: #fff;
  position: absolute;
  right: 0;
  top: 5%;
}
.sec2-card:hover .sec2-card-del1::before {
  content: " ";
  display: block;
  width: 19px;
  height: 3px;
  background: #fff;
  position: absolute;
  left: 0;
  top: 7%;
}
.sec2-card:hover .sec2-card-del1::after {
  content: " ";
  display: block;
  width: 19px;
  height: 3px;
  background: #fff;
  position: absolute;
  right: 0;
  top: 7%;
}
.sec2-card:hover .p-title span {
  padding-left: 0px;
}

/* Media query for screens larger than a certain width (adjust as needed) */
@media screen and (max-width: 768px) {
 
  .sec2-card {
    /* width: 90%; */
    /* width: auto; */
   width: 265px;
   height: 350px;
  }
  .p-des {
    font-size: 12px;
  }
  .home-section2 {
    /* background: rgba(19, 44, 69, 0.92); */
    /* height: 1849px; */
    height: 2093px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: unset;
    margin-top: 0px;
    width: 100%;
    /* z-index: -1; */
  }
  .sec2-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* height: auto; */
    background: rgba(19, 44, 69, 0.92);
  }
  .home-sec2-content {
    max-width: 85%;
    padding-left: 0px;
    padding-right: 0px;
    margin: auto;
    justify-content: flex-start;
  }
  .sec2-cards-div {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    /* margin-top: 60px; */
    justify-content: center;
    align-items: center;
    gap: 37px;
  }
  .h-sec2-head p {
    color: #fff;
    /* font-family: Rubik; */
    font-size: 25.001px;
    font-style: normal;
    font-weight: 700;
    line-height: 30.202px;
  }
  .h-sec2-head div {
    width: 15px;
    /* height: 88px; */
    flex-shrink: 0;
    background: #1ec199;
  }
  .sec2-card-del {
    position: relative;
    bottom: 69px;
  }
  .sec2-card-del1 {
    bottom: 74px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .sec2-card {
    /* width: calc(47% - 20px); */
    width: 281px;
    height: 332px;
  }
  .sec2-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* height: auto; */
    background: rgba(19, 44, 69, 0.92);
  }
  .home-section2 {
    height: 163vh;
    overflow: unset;
    /* margin-top: 51px; */
    width: 100%;
  }
  .sec2-card img {
    object-fit: fill;
  }
  .sec2-card-del1 {
    bottom: 70px;
  } 
  .sec2-card-del {
    bottom: 70px;
  }
}
@media (min-width: 1025px) and (max-width: 1211px) {
  .home-section2 {
    height: 150vh;
}
  .p-title {
    font-size: 13px;
    line-height: 18.359px;
  }
  .p-des {
    font-size: 12px;
    line-height: 13px;
  }
  .sec2-card {
    width: calc(29% - 20px);
    /* width: 282px; */
    height: 336px;
  }
  .sec2-card-del::before {
    top: 18%;
    width: 14px;
    height: 3px;
  }
  .sec2-card-del {
    position: relative;
    bottom: 62px;
  }
  .sec2-card-del1::before {
    width: 14px;
    height: 3px;
    top: 10%;
  }
  .sec2-card:hover .sec2-card-del::before {
    width: 19px;
    height: 3px;
    top: 3%;
  }
  .sec2-card:hover .sec2-card-del::after {
    height: 3px;
    top: 3%;
  }
  .sec2-card:hover .sec2-card-del1::before {
    height: 3px;
    top: 3%;
  }
  .sec2-card:hover .sec2-card-del1::after {
    height: 3px;
    top: 3%;
  }
  .sec2-card-del1 {
    bottom: 65px;
  }
}
@media (min-width: 1211px) and (max-width: 1270px) {
  .p-title {
    font-size: 13px;
    line-height: 18.359px;
  }
  .p-des {
    font-size: 13px;
  }
  .sec2-card {
    width: calc(17% - 3px);
}
}
