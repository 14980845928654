.error_page{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    min-height: 79vh;
}
.error_page>h3{
    font-size: 40px;
    font-family: var(--font-base);
}