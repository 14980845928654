/* CustomDropdown.css */

.custom-dropdown {
  position: relative;
  width: 100px;
}

.dropdown-header {
  cursor: pointer;
  border: 2px solid var(--color-blue); /* Blue border */
  background-color: #fff; /* White background */
  border-radius: 4px;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.dropdown-header.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.selected-country {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.country-logo {
  margin-right: 8px;
  width: 19px;
  height: 14px;
}

.country-name {
  font-size: 14px;
  font-family: var(--font-base);
  color: var(--color-blue);
  font-weight: 600;

}
.country-name1 {
  font-size: 14px;
  font-family: var(--font-base);
  color: var(--color-blue);
  font-weight: 600;
  margin-left: 8px;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  border: 2px solid var(--color-blue); /* Blue border */
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #fff; /* White background */
}

.dropdown-option {
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-option:hover {
  background-color: #f0f0f0; /* Light gray background on hover */
}
