.TC_main_div{
    width: 100%;
    max-width: var(--max-width);
    box-shadow: 0px 0px 18.6px 0px #0000001A;
    margin: 2rem auto;
    padding-top: 4rem;
    margin-bottom: 5rem;
}
.TC_title_div{
    background: #02245BCC;
    max-width: 685px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 114px;
    height: auto;
}
.TC_title_div > h2{
    font-size: 36px;
    font-weight: 700;
    font-family: var(--font-rubik);
    color: var(--color-white);
    line-height: 27.36px;
}
.TC_content_div{
    padding: 3.5rem 2.5rem 3.5rem 2.5rem;
}
.TC_content_div >p {
    font-size: 14px;
    font-weight: 600;
    font-family: var(--font-base);
    line-height: 24px;
    color: #444444;
    text-align: justify;
}

.TC_list{
    padding: 1rem;
}
.TC_list >li{
    /* list-style-type: decimal; */
    color: #444444;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    font-family: var(--font-base);
    padding-bottom: 10px;
    text-align: justify;
    display: flex;
    align-items: start;
    gap: 16px;
}
.TC_content2_div{
    margin-top: 1rem;
}
.TC_contactus_title{
    font-size: 14px;
    font-weight: 600;
    font-family: var(--font-base);
    line-height: 24px;
    color: #444444;
    padding-bottom: 1.5rem;
}
.TC_contactus{
    margin-top: 10px;
}
.TC_contactus > p{
    font-size: 14px;
    font-weight: 600;
    font-family: var(--font-base);
    line-height: 24px;
    color: #444444;
    padding-bottom: 6px;
}
.TC_link{
    color: blue;
    word-break: break-all;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--font-base);
}
.TC_list_p1{
    width: 2%;
    text-align: end;
}
.TC_list_p2{
    width: 98%;
}
@media (max-width: 768px) {
    .TC_content_div {
        padding: 3.5rem 1rem 3.5rem 1rem;
    }
    .TC_list {
        padding: 10px;
    }
    .TC_title_div {
        min-height: unset;
        height: auto;
        padding: 22px 8px 22px 20px;
        width: 80%;
        justify-content: flex-start;
    }
    .TC_title_div > h2 {
        font-size: 30px;
        line-height: 34.36px;
    }
}
