.aom_main_wrapp {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aom_back_img {
  position: absolute;
  /* transform: translate(50%, 50%); */
  left: 0px;
  top: 0px;
  width: 100vw;
  min-height: 100vh;
  z-index: -1;
  height: -webkit-fill-available;
}

.aom_base_wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* width: 100%; */
}

.aom_text_wrapp {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 598px;
}

.aom_heading_text {
  font-family: var(--font-rubik);
  font-size: 36px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: 0em;
  color: #fff;
  margin-bottom: 10px;
  /* text-align: left; */
}

.aom_des_text {
  font-family: var(--font-base);
  font-size: 14px;
  font-weight: 600;
  line-height: 31px;
  /* letter-spacing: 0em; */
  color: #fff;
  text-align: center;

  /* text-align: left; */
}

.aom_squres_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2%;
  width: 100%;
  margin-top: 2rem;
}

.aom_single_squre {
  width: 49%;
  border-radius: 25px;
  border: 1px solid #fff;
  padding: 1rem 2rem;

  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;

  min-height: 280px;
}

.aom_single_squre > h3 {
  font-family: var(--font-base);
  font-size: 40px;
  font-weight: 600;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}

.aom_single_squre > p {
  font-family: var(--font-base);
  font-size: 14px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: justify;

  color: #fff;
}
@media (min-width: 401px) and (max-width:768px){
  .aom_base_wrapp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
    /* transform: translateY(-6rem); */
}
  .aom_main_wrapp {
    /* min-height: 130vh; */
    min-height: 107vh;
  }

  .aom_squres_wrapp {
    flex-direction: column;
    gap: 1rem;
  }

  .aom_single_squre {
    width: 100%;
    padding: 0.8rem 1rem;
    min-height: 220px;
  }

  /* .aom_base_wrapp {
    background-color: var(--color-blue);
    padding-bottom: 4rem;
  } */

  .aom_back_img {
    /* height: 130vh; */
    height: 107vh;
  }

  .aom_heading_text {
    font-size: 25px;
    line-height: 36px;
  }

  .aom_single_squre > h3 {
    font-size: 26px;
    line-height: 28px;
  }
  .aom_single_squre > p {
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
  }
  .aom_des_text {
    text-align: justify;
    padding: 0 10px;
    font-size: 12px;
    line-height: 22px;
  }
}

@media (max-width: 400px) {
  .aom_base_wrapp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
    /* transform: translateY(-6rem); */
}
  .aom_main_wrapp {
    min-height: 130vh;
    /* min-height: 107vh; */
  }

  .aom_squres_wrapp {
    flex-direction: column;
    gap: 1rem;
  }

  .aom_single_squre {
    width: 100%;
    padding: 0.8rem 1rem;
    min-height: 220px;
  }

  /* .aom_base_wrapp {
    background-color: var(--color-blue);
    padding-bottom: 4rem;
  } */

  .aom_back_img {
    height: 133vh;
    /* height: 107vh; */
  }

  .aom_heading_text {
    font-size: 25px;
    line-height: 36px;
  }

  .aom_single_squre > h3 {
    font-size: 26px;
    line-height: 28px;
  }
  .aom_single_squre > p {
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
  }
  .aom_des_text {
    text-align: justify;
    padding: 0 10px;
  }
}


@media (min-width: 769px) and (max-width:1024px){
.aom_single_squre {
  min-height: 377px;
  max-height: 385px;
}
}