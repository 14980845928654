.recentBlogCard_main_wrapp {
  /* min-width: 250px; */
  /* width: 98%; */
  width: calc(87% - 4px) !important;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.09);
  height: 445px;
  /* margin: 10px; */
  border: none;
  transition: all ease 0.5s;
  margin: 10px auto;
}

.recentBlogCard_main_wrapp:hover {
  transform: translateY(10px);
}

.recentBlogCard_img_wrapp {
  position: relative;
  width: 100%;
  height: 60%;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}

.recentBlogCard_img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  border: 1px solid white;
    border-radius: 19px;
  object-fit: cover;
}

.recentBlogCard_shap {
  position: absolute;
  bottom: -1px;
  right: -1px;
  /* height: 100px; */
  width: 110px;
}

.recentBlogCard_arrow {
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 60px;
  width: 60px;
  transition: all ease 0.5s;
}

.recentBlogCard_main_wrapp:hover .recentBlogCard_arrow {
  transform: rotate(45deg);
  transition: all ease 0.3s;
}

/* text wrapp start */

.recentBlogCard_text_wrapp {
  width: 100%;
  height: 38%;
  padding: 1rem;
}

.recentBlogCard_text_wrapp > h4 {
  color: #000;
  font-family: var(--font-base);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 125% */
  margin-bottom: 10px;
}

.recentBlogCard_text_wrapp > p {
  color: #919191;
  font-family: var(--font-base);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.5px; /* 153.571% */
}

/* text wrapp end */
@media screen and (max-width: 768px) {
  .recentBlogCard_text_wrapp > p{
    text-align: justify;
  }
  .recentBlogCard_main_wrapp {
    /* min-width: 250px; */
    width: 90%!important;
    max-width: 350px;
}
}